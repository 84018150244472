/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";
import Datetime from "react-datetime";
import copy from "copy-to-clipboard";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PrintIcon from "@material-ui/icons/Print";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import Check from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import RefreshIcon from "@material-ui/icons/Refresh";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import nopic from "assets/img/default-avatar.png";

import styles from "assets/jss/main-style.js";
import SaleOrderList from "./SaleOrderList";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

function StepIcon(props) {
  const { active, completed } = props;
  const icons = {
    1: <CheckCircleOutlineIcon color="disabled" />,
    2: <CheckCircleOutlineIcon color="disabled" />,
    3: <CheckCircleOutlineIcon color="disabled" />,
    4: <CancelIcon color="disabled" />,
    5: <CancelIcon color="disabled" />,
  };
  const icons_complete = {
    1: <CheckCircleOutlineIcon color="primary" />,
    2: <CheckCircleOutlineIcon color="primary" />,
    3: <CheckCircleOutlineIcon color="primary" />,
    4: <CancelIcon color="error" />,
    5: <CancelIcon color="error" />,
  };
  const icons_active = {
    1: <CheckCircleIcon color="primary" />,
    2: <CheckCircleIcon color="primary" />,
    3: <CheckCircleIcon color="primary" />,
    4: <CancelIcon color="error" />,
    5: <CancelIcon color="error" />,
  };
  return (
    <div>
      {completed
        ? icons_complete[String(props.icon)]
        : active
        ? icons_active[String(props.icon)]
        : icons[String(props.icon)]}
    </div>
  );
}

const initAttr = {
  code: "",
  balance: "0.00",
  call_amount: "0.00",
  call_bank: "0",
  call_date: null,
  call_pic: "",
  call_remark: "",
  call_time: null,
  currency: "THB",
  date_create: "0000-00-00 00:00:00",
  date_invoice: "0000-00-00 00:00:00",
  date_update: "0000-00-00 00:00:00",
  date_record: new Date(),
  date_expire: new Date(),
  device_id: "",
  fileslip: "nopic.jpg",
  invoice_id: "",
  kind: "1",
  master_code: "0",
  master_name: "",
  member_code: "0",
  member_name: "",
  chain: "",
  paid: "0.00",
  payment_type: "",
  product_code: "",
  product_name: "",
  raw_qrcode: "",
  ref_id: "",
  remark: "",
  sendapi: "N",
  service_life: "0",
  status: "0",
  token: "",
  bill: "",
  com_name: "",
  com_tel: "",
  com_id: "",
  com_email: "",
  address: "",
  amount: 0,
  vat: 0,
  total: 0,
  user_create: "",
  user_update: "",
  enable: "Y",
  renew: "N",
  receipt: "N",
  robot_id: "",
  member_id: "",
  member_pic: "",
};

const initFind = {
  search: "",
  order_by: "tb.code",
  order_sort: "desc",
  status: "3",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function SaleOrder({ t }) {
  const mod = "saleorder";
  const prefix = "attr";
  const classes = useStyles();
  const txtSearch = useRef(null);

  const initHeader = [
    { code: "tb.code", name: t(`${prefix}.code`), order: "desc" },
    { code: "tb.member_code", name: `Member` },
    { code: "tb.date_create", name: `Date` },
    { code: "tb.payment_type", name: `Type` },
    { code: "tb.receipt", name: `Receipt` },
    { code: "tb.amount", name: `Total` },
    { code: "tb.vat", name: `Vat` },
    { code: "tb.total", name: `Net Total` },
    { code: "tb.status", name: `Status` },
  ];
  const alignValue = [0, 2, 3, 4, 5, 6, 7, 8];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.right,
    classes.right,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const txtMember = useRef(null);
  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cboType, setCboType] = useState([]);
  const [cboStatus, setCboStatus] = useState([]);
  const [list, setList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [modalMember, setModalMember] = useState(false);
  const [modalProduct, setModalProduct] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        if (id === undefined) {
          setCboStatus(result.stat);
          view(find);
        } else if (id === "add") {
          setAttr(initAttr);
          setCboType(result.type);
          setCboStatus(result.stat);
        } else {
          setCboType(result.type);
          setCboStatus(result.stat);
          open();
        }
      } else {
        Func.exitApp();
      }
    });
  };

  const copyText = (text) => {
    copy(text);
    showNotify("Copy success", "success");
  };

  const search = (e) => {
    e.preventDefault();

    view({ ...find, search: txtSearch.current.value, page: 1 });
    setFind({ ...find, search: txtSearch.current.value, page: 1 });
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const selectChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.value });
  };

  const checkChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.checked ? "Y" : "N" });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      status: data.status,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              btnOpen(item),
              <span>
                <img src={item.member_pic} width="20" /> {item.member_name}
              </span>,
              Func.dateTimeDisplay(item.date_create),
              item.payment_type,
              displayReceipt(item),
              Func.numberDisplay(item.amount),
              Func.numberDisplay(item.vat),
              Func.numberDisplay(item.total),
              displayStatus(item),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.status_color}`}>
        <i className={item.status_icon} /> {item.status_name}
      </code>
    );
  };

  const displayReceipt = (item) => {
    if (item.receipt === "Y")
      return (
        <code className={`color-info`}>
          <i className="far fa-check-circle" /> รับใบเสร็จ
        </code>
      );
    else return null;
  };

  const searchProduct = () => {
    setProductList([]);

    PostData(mod, "productsearch", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setProductList((productList) => [
            ...productList,
            [
              <img src={item.filepic} width="30" />,
              displayProduct(item.product_name, item),
              displayProduct(item.price_name, item),
              displayProduct(item.total, item),
            ],
          ]);
        });
      }
    });
  };

  const displayProduct = (text, item) => {
    return (
      <Button
        simple
        color="github"
        className={classes.actionButton + " " + classes.actionButtonRound}
        onClick={() => findProduct(item)}
      >
        {text}
      </Button>
    );
  };

  const findProduct = (item) => {
    setAttr({
      ...attr,
      amount: attr.amount * 1 + item.amount * 1,
      vat: attr.vat * 1 + item.vat * 1,
      total: attr.total * 1 + item.price * 1,
    });

    setList((list) => [...list, item]);

    setModalProduct(false);
  };

  const btnRemoveProduct = (item) => {
    return (
      <Button
        round
        color="danger"
        className={classes.actionButton + " " + classes.actionButtonRound}
        onClick={() => removeProduct(item)}
      >
        <DeleteIcon className={classes.icon} />
      </Button>
    );
  };

  const removeProduct = (item) => {
    let temp = [];
    let n = 0;

    list.map((data, i) => {
      if (data.code === item.code) {
      } else {
        temp[n] = data;
        n++;
      }
    });

    setList(temp);
    setAttr({
      ...attr,
      amount: attr.amount * 1 - item.amount * 1,
      vat: attr.vat * 1 - item.vat * 1,
      total: attr.total * 1 - item.price * 1,
    });
  };

  const searchCustomer = (e) => {
    e.preventDefault();

    setMemberList([]);

    if (txtMember.current.value == "") return;

    PostData(mod, "membersearch", {
      token: app.token,
      lang: app.lang,
      search: txtMember.current.value,
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setMemberList((memberList) => [
            ...memberList,
            [
              <img src={item.filepic} width="30" />,
              displayMember(item.id, item),
              displayMember(item.name, item),
              displaySelect(item),
            ],
          ]);
        });
      }
    });
  };

  const displayMember = (text, item) => {
    return (
      <Button
        simple
        color="github"
        className={classes.actionButton + " " + classes.actionButtonRound}
        onClick={() => findMember(item)}
      >
        {text}
      </Button>
    );
  };

  const displaySelect = (item) => {
    return (
      <Button
        simple
        color="github"
        className={classes.actionButton + " " + classes.actionButtonRound}
        onClick={() => findMember(item)}
      >
        <i className="fas fa-chevron-right" />
      </Button>
    );
  };

  const findMember = (item) => {
    setAttr({
      ...attr,
      member_id: item.id,
      member_name: item.name,
      member_pic: item.filepic,
    });

    setModalMember(false);
  };

  const open = () => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr({
          ...result.data,
          date_expire: new Date(result.data.date_expire),
        });

        setLoading(false);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (!Func.verifyLength(attr.member_id, 1)) {
      result = false;
    }

    return result;
  };

  const add = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "add", {
              token: app.token,
              lang: app.lang,
              member_id: attr.member_id,
              data: {
                date_record: Func.dateFormat(attr.date_record),
                renew: attr.renew,
                robot_id: attr.robot_id,
                receipt: attr.receipt,
                com_name: attr.com_name,
                com_tel: attr.com_tel,
                com_id: attr.com_id,
                com_email: attr.com_email,
                address: attr.address,
              },
              order: list,
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");
                window.location.assign(result.url);
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const btnOpen = (item) => {
    return (
      <NavLink to={`/admin/${mod}/${item.code}`}>
        <Button
          color="primary"
          simple
          className={classes.actionButton + " " + classes.actionButtonRound}
        >
          {item.id}
        </Button>
      </NavLink>
    );
  };

  const cancelOrder = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="คุณต้องการยกเลิกบิล ใช่หรือไม่"
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "cancel", {
            token: app.token,
            lang: app.lang,
            code: attr.code,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              open();
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.danger}
        cancelBtnCssClass={classes.button}
        confirmBtnText="ยกเลิกรายการ"
        cancelBtnText="ปิด"
        showCancel
      >
        ยกเลิกรายการ #{attr.code}
      </SweetAlert>
    );
  };

  const del = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_delete_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "delete", {
            token: app.token,
            lang: app.lang,
            code: item.code,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              view(find);
              showNotify(t("alert.deletecomplete"), "success");
            } else {
              showNotify(t("alert.deleteerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_delete_msg")} #{item.code}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <>
        <NavLink to={`/admin/${mod}/${item.code}`}>
          <Button
            round
            color="success"
            className={classes.actionButton + " " + classes.actionButtonRound}
          >
            <EditIcon className={classes.icon} />
          </Button>
        </NavLink>
      </>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.viewlist")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <form noValidate onSubmit={search}>
                    <TextField
                      fullWidth
                      InputProps={{
                        inputRef: txtSearch,
                        startAdornment: (
                          <InputAdornment position="start">
                            {t("element.search")} :
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </form>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        สถานะ
                      </MenuItem>
                      {cboStatus &&
                        cboStatus.map((item) => (
                          <MenuItem
                            key={item.code}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.code}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      renderHeader(header[4]),
                      renderHeader(header[5]),
                      renderHeader(header[6]),
                      renderHeader(header[7]),
                      renderHeader(header[8]),
                    ]}
                    tableData={record}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[5, 6, 7]}
                    colorsColls={["primary", "danger", "success"]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}></InputLabel>
                    <Select
                      value={find.perPage}
                      onChange={(e) => {
                        view({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                        setFind({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      {cboPerPage &&
                        cboPerPage.map((item) => (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {t(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={8}
                  lg={9}
                  className={classes.right}
                >
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    count={pageTotal}
                    showFirstButton
                    showLastButton
                    page={pageNo}
                    onChange={(e, p) => {
                      view({ ...find, page: p });
                      setFind({ ...find, page: p });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderAdd = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody className={classes.right}>
              <NavLink to={`/admin/${mod}`}>
                <Button color="warning">
                  <ArrowBackIcon /> {t("element.back")}
                </Button>
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={5}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Information</h4>
            </CardHeader>
            <CardBody>{renderInfoAdd()}</CardBody>
          </Card>
        </GridItem>
        <GridItem xs={7}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Order</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      "#",
                      "สินค้า",
                      "ระยะเวลา",
                      "ราคา",
                      <Button
                        round
                        color="primary"
                        className={
                          classes.actionButton + " " + classes.actionButtonRound
                        }
                        onClick={() => {
                          searchProduct();
                          setModalProduct(true);
                        }}
                      >
                        <AddIcon className={classes.icon} />
                      </Button>,
                    ]}
                    tableData={list.map((item) => {
                      return [
                        item.code,
                        item.product_name,
                        item.price_name,
                        item.total,
                        btnRemoveProduct(item),
                      ];
                    })}
                    customHeadClassesForCells={[3, 4]}
                    customHeadCellClasses={[classes.right, classes.right]}
                    customClassesForCells={[3, 4]}
                    customCellClasses={[classes.right, classes.right]}
                    coloredColls={[3]}
                    colorsColls={["primary"]}
                  />
                  <Table
                    striped
                    hover
                    tableData={[
                      [<b>Amount</b>, Func.numberDisplay(attr.amount)],
                      [<b>Vat</b>, Func.numberDisplay(attr.vat)],
                      [<b>Total</b>, Func.numberDisplay(attr.total)],
                    ]}
                    customClassesForCells={[0, 1]}
                    customCellClasses={[classes.right, classes.right]}
                    coloredColls={[1]}
                    colorsColls={["primary"]}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardBody style={{ textAlign: "center" }}>
              <Button color="success" onClick={add}>
                <SaveIcon /> {t("element.save")}
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>#{attr.id}</h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <NavLink to={`/admin/${mod}`}>
                    <Button color="warning">
                      <ArrowBackIcon /> {t("element.back")}
                    </Button>
                  </NavLink>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>{renderStatus()}</GridItem>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Information</h4>
            </CardHeader>
            <CardBody>{renderInfo()}</CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Order</h4>
            </CardHeader>
            <CardBody>
              <SaleOrderList id={id} />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardBody style={{ textAlign: "center" }}>
              {attr.status !== "3" ? null : (
                <Button
                  color="success"
                  onClick={() => window.open(`${attr.bill}`, "_blank")}
                >
                  <PrintIcon /> พิมพ์ใบเสร็จ
                </Button>
              )}

              {attr.status === "9" ? null : (
                <Button color="danger" onClick={() => cancelOrder()}>
                  <CancelIcon /> ยกเลิก
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>{renderDetail()}</GridItem>
      </GridContainer>
    );
  };

  const renderInfoAdd = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <br />
            <br />
            <div className="fileinput text-center">
              <div
                className={"thumbnail"}
                style={{ maxWidth: "100%", textAlign: "center" }}
              >
                <img src={attr.member_pic === "" ? nopic : attr.member_pic} />
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={8}>
            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  success={true}
                  labelText="Member ID"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: attr.member_id,
                    disabled: true,
                    onClick: () => {
                      setModalMember(true);

                      setTimeout(function () {
                        txtMember.current.select();
                      }, 500);
                    },
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  success={true}
                  labelText="Member"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: attr.member_name,
                    disabled: true,
                    onClick: () => {
                      setModalMember(true);

                      setTimeout(function () {
                        txtMember.current.select();
                      }, 500);
                    },
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <InputLabel className={classes.labelDpk}>วันที่บิล</InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    dateFormat="DD/MM/yyyy"
                    closeOnSelect
                    value={attr.date_record}
                    onChange={(value) => {
                      setAttr({ ...attr, date_record: value });
                    }}
                    className={classes.center}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attr.renew === "Y"}
                    name="renew"
                    tabIndex={-1}
                    onChange={checkChange}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={`ต่ออายุ`}
              />
            </div>
          </GridItem>
          {attr.renew === "Y" ? (
            <GridItem xs={12}>
              <CustomInput
                labelText="รหัส Robot"
                id="robot_id"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: inputChange,
                  value: attr.robot_id,
                }}
              />
            </GridItem>
          ) : null}
          <GridItem xs={12}>
            <hr />
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attr.receipt === "Y"}
                    name="receipt"
                    tabIndex={-1}
                    onChange={checkChange}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={`ต้องการใบเสร็จ`}
              />
            </div>
          </GridItem>
          {attr.receipt === "Y" ? (
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="ชื่อบริษัท"
                    id="com_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: inputChange,
                      value: attr.com_name,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="เบอร์โทร"
                    id="com_tel"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: inputChange,
                      value: attr.com_tel,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="เลขที่ผู้เสียภาษี"
                    id="com_id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: inputChange,
                      value: attr.com_id,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText="อีเมล"
                    id="com_email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: inputChange,
                      value: attr.com_email,
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    multiline
                    labelText="ที่อยู่"
                    id="address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: inputChange,
                      value: attr.address,
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          ) : null}
        </GridContainer>
      </form>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={4}>
            <img src={attr.member_pic} width="100%" />
            {formView("สมาชิก", "member_name", attr.member_name)}
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attr.receipt === "Y"}
                    name="receipt"
                    tabIndex={-1}
                    onChange={checkChange}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={`ต้องการใบเสร็จ`}
              />
            </div>
          </GridItem>
          <GridItem xs={8}>
            {formView("ชื่อบริษัท", "com_name", attr.com_name)}
            {formView("เบอร์โทร", "com_tel", attr.com_tel)}
            {formView("เลขที่ผู้เสียภาษี", "com_id", attr.com_id)}
            {formView("อีเมล", "com_email", attr.com_email)}
            {formView("ที่อยู่", "address", attr.address, true)}
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const formView = (title, id, value, multiline = false) => {
    return (
      <CustomInput
        success
        labelText={title}
        id={id}
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          onChange: inputChange,
          value: value,
          disabled: true,
          multiline: multiline,
        }}
        disabled
      />
    );
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>
                {Func.dateTimeDisplay(attr.date_create)}
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>
                {Func.dateTimeDisplay(attr.date_update)}
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderStatus = () => {
    return (
      <Stepper
        activeStep={
          attr.status === "8"
            ? 3
            : attr.status === "9"
            ? 4
            : attr.status === "3"
            ? 2
            : attr.status === "1"
            ? 0
            : attr.status === "2"
            ? 1
            : attr.status * 1
        }
        // activeStep={0}
        alternativeLabel
        className={classes.bgTransparent}
      >
        {cboStatus.map((item, i) => (
          <Step key={i}>
            <StepLabel StepIconComponent={StepIcon}>{item.name}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  };

  const renderModalMember = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalMember}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalMember(false)}
        aria-labelledby="modal-customer-title"
        aria-describedby="modal-customer-description"
      >
        <DialogTitle
          id="modal-customer-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalMember(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Member</h4>
        </DialogTitle>
        <DialogContent id="modal-customer" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <form noValidate onSubmit={searchCustomer}>
                <CustomInput
                  labelText={t("element.search")}
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtMember,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
              </form>
            </GridItem>
            <GridItem xs={12}>
              <Table
                hover
                tableData={memberList}
                customHeadClassesForCells={[3]}
                customHeadCellClasses={[classes.right]}
                customClassesForCells={[3]}
                customCellClasses={[classes.right]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  const renderModalProduct = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalProduct}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalProduct(false)}
        aria-labelledby="modal-product-title"
        aria-describedby="modal-product-description"
      >
        <DialogTitle
          id="modal-product-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalProduct(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Product</h4>
        </DialogTitle>
        <DialogContent id="modal-product" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                hover
                tableData={productList}
                customHeadClassesForCells={[3]}
                customHeadCellClasses={[classes.right]}
                customClassesForCells={[3]}
                customCellClasses={[classes.right]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderPage = () => {
    if (id === undefined) {
      return renderViewList();
    } else if (id === "add") {
      return renderAdd();
    } else {
      return renderEdit();
    }
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderPage()}
      {renderModalMember()}
      {renderModalProduct()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(SaleOrder);
