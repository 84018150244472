import { SET_LANGUAGE, SET_TOKEN } from "../types";
import i18n from "i18next";

export const setLanguage = (lang) => (dispatch) => {
  i18n.changeLanguage(lang);
  dispatch({
    type: SET_LANGUAGE,
    payload: lang,
  });
};

export const setToken = (token) => (dispatch) => {
  dispatch({
    type: SET_TOKEN,
    payload: token,
  });
};
