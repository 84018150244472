/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";

// @material-ui/icons
import DateRangeIcon from "@material-ui/icons/DateRange";
import PrintIcon from "@material-ui/icons/Print";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  amount: 0.0,
  vat: 0.0,
  total: 0.0,
};

const initFind = {
  search: "",
  date_start: moment(Func.dateFirstMonth()),
  date_stop: moment(Func.dateLastMonth()),
  order_by: "tb.id",
  order_sort: "desc",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function RpStat({ t }) {
  const mod = "rp_stat";
  const classes = useStyles();

  const initHeader = [
    { code: "", name: "วันที่", sort: false },
    { code: "", name: "สมาชิก", sort: false },
    { code: "", name: "Robot", sort: false },
    { code: "", name: "Total", sort: false },
    { code: "", name: "Vat", sort: false },
    { code: "", name: "Net total", sort: false },
  ];
  const alignValue = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const alignItem = [
    classes.center,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
  ];

  const app = useSelector((state) => state.appState);

  const [record, setRecord] = useState([]);
  const [find, setFind] = useState(initFind);
  const [attr, setAttr] = useState(initAttr);
  const [header, setHeader] = useState(initHeader);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, []);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setLoading(true);
    setRecord([]);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateTimeFormat(data.date_start.toDate()),
      stop: Func.dateTimeFormat(data.date_stop.toDate()),
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              Func.dateDisplay(item.dt),
              numberDisplay(item.cm_all, 0),
              numberDisplay(item.c_robot, 0),
              numberDisplay(item.s_amount),
              numberDisplay(item.s_vat),
              numberDisplay(item.s_saleorder, 2, "info"),
            ],
          ]);
        });

        if (result.data.length > 0)
          setRecord((record) => [
            ...record,
            [
              <b>Total</b>,
              <b>{numberDisplay(result.sum.total_member, 0)}</b>,
              <b>{numberDisplay(result.sum.total_robot, 0)}</b>,
              <b>{numberDisplay(result.sum.total_amount, 2)}</b>,
              <b>{numberDisplay(result.sum.total_vat, 2)}</b>,
              <b>{numberDisplay(result.sum.total_saleorder, 2, "info")}</b>,
            ],
          ]);
      }
      setLoading(false);
    });
  };

  const PrintInvoice = (data) => {
    PostData(mod, "invoice", {
      token: app.token,
      lang: app.lang,
      amount: attr.amount,
      vat: attr.vat,
      total: attr.total,
      start: Func.dateTimeFormat(data.date_start.toDate()),
      stop: Func.dateTimeFormat(data.date_stop.toDate()),
    }).then((result) => {
      if (result.status === "OK") {
        window.open(result.url, "_blank");
      }
    });
  };

  const numberDisplay = (number, point = 2, clr = "dark") => {
    number = number === undefined ? 0 : number * 1;
    let result = number
      .toFixed(point)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

    if (number < 0) return <span className="color-danger">{result}</span>;
    else if (number > 0)
      return <span className={`color-${clr}`}>{result}</span>;
    else return <span className="color-default">-</span>;
  };

  const renderSearchDate = () => {
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days"),
      ],
      "3 Days": [moment(start).subtract(3, "days"), moment(end)],
      "5 Days": [moment(start).subtract(5, "days"), moment(end)],
      "1 Week": [moment(start).subtract(7, "days"), moment(end)],
      "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
      "1 Month": [moment(start).subtract(1, "months"), moment(end)],
    };
    return (
      <div>
        <DateTimeRangeContainer
          ranges={ranges}
          start={find.date_start}
          end={find.date_stop}
          local={{ format: "DD/MM/YYYY HH:mm", sundayFirst: false }}
          applyCallback={(start, stop) => {
            view({ ...find, date_start: start, date_stop: stop });
            setFind({ ...find, date_start: start, date_stop: stop });
          }}
        >
          <TextField
            id="search"
            fullWidth
            value={`${find.date_start.format("DD/MM/YYYY HH:mm")} 
            ${t(`element.to`)} 
            ${find.date_stop.format("DD/MM/YYYY HH:mm")}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {t("element.date")} :
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon />
                </InputAdornment>
              ),
              autoComplete: "off",
              readOnly: true,
            }}
          />
        </DateTimeRangeContainer>
      </div>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card className={classes.boxPrimary}>
            <CardHeader color="primary" icon className={classes.boxHead}>
              <h4 className={classes.boxTitle}>
                <i className="fas fa-file-alt color-primary" />
                {t("element.report")}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={12} md={6}>
                  {renderSearchDate()}
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      renderHeader(header[4]),
                      renderHeader(header[5]),
                    ]}
                    tableData={record}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[]}
                    colorsColls={[]}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderLoading()}
    </div>
  );
}

export default withNamespaces()(RpStat);
