/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import copy from "copy-to-clipboard";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import CheckIcon from "@material-ui/icons/Check";

// core components
import CustomDropdown from "./DropDown";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";
import Credit from "./Credit";
import MemberPoint from "./MemberPoint";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
  name: "",
  email: "",
  firstname: "",
  lastname: "",
  line_id: "",
  line_name: "",
  line_email: "",
  line_status: "",
  google_id: "",
  google_name: "",
  google_email: "",
  notify_token: "",
  sponsor_code: "",
  sponsor_name: "",
  com_name: "",
  com_id: "",
  com_tel: "",
  com_email: "",
  secretkey: "",
  address: "",
  rank_code: "",
  bank_code: "",
  acc_name: "",
  acc_no: "",
  balance: "",
  date_create: "",
  tel: "",
  line_enable: "N",
  enable: "Y",
  confirm: "Y",
};

const initFind = {
  search: "",
  order_by: "tb.code",
  order_sort: "desc",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function Member({ t }) {
  const mod = "member";
  const prefix = "attr";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);

  const initHeader = [
    { code: "tb.filepic", name: t(`${prefix}.image`), sort: false },
    { code: `tb.code`, name: t(`${prefix}.code`), order: "desc" },
    { code: `tb.name`, name: t(`${mod}.name`) },
    { code: `tb.line_status`, name: "Line Status" },
    { code: `tb.line_email`, name: "E-mail" },
    { code: `tb.com_name`, name: "บริษัท" },
    { code: `tb.date_create`, name: "วันสมัคร" },
    { code: "tb.enable", name: t(`${prefix}.enable`) },
  ];
  const alignValue = [0, 1, 6, 7];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cboBank, setCboBank] = useState([]);
  const [modalAction, setModalAction] = useState(false);
  const [editType, setEditType] = useState("");
  const [id, setId] = useState("");
  const [linkManual, setLinkManual] = useState("");

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboBank(result.bank);

        view(find);
        setLinkManual(result.linkmanual);
      } else {
        Func.exitApp();
      }
    });
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const selectChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.value });
  };

  const checkChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.checked ? "Y" : "N" });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              <img src={item.filepic} width="30" />,
              btnOpen(item),
              item.name,
              item.line_status,
              item.line_email,
              item.com_name,
              Func.dateDisplay(item.date_create),
              Func.checkDisplay(item.enable),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const copyText = (text) => {
    copy(text);
    showNotify("Copy success", "success");
  };

  const btnLink = (item) => {
    return (
      <Button
        color="info"
        simple
        className={classes.actionButton + " " + classes.actionButtonRound}
        onClick={() => copyText(item.link)}
      >
        Copy
      </Button>
    );
  };

  const btnOpen = (item) => {
    return (
      <Button
        color="primary"
        simple
        className={classes.actionButton + " " + classes.actionButtonRound}
        onClick={() => {
          open(item.code);
          setId(item.code);
          setEditType("edit");
          setModalAction(true);
        }}
      >
        {item.id}
      </Button>
    );
  };

  const open = (code) => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: code,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        setLoading(false);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (!Func.verifyLength(attr.firstname, 1)) result = false;

    return result;
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);
            setModalAction(false);

            PostData(mod, "edit", {
              token: app.token,
              lang: app.lang,
              code: attr.code,
              data: {
                com_name: attr.com_name,
                com_id: attr.com_id,
                com_tel: attr.com_tel,
                com_email: attr.com_email,
                address: attr.address,
                enable: attr.enable,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                open(attr.code);
                view(find);

                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <CustomDropdown
        dropup
        buttonText="Action"
        buttonProps={{
          color: "primary",
          size: "sm",
        }}
        dropdownList={[
          {
            id: "edit",
            title: (
              <div>
                <i className="fas fa-user-edit color-primary" /> Edit
              </div>
            ),
          },
          {
            id: "credit",
            title: (
              <div>
                <i className="fas fa-coins color-primary" />{" "}
                {t(`${mod}.credit`)}
              </div>
            ),
          },
        ]}
        onClick={(value) => {
          open(item.code);
          setId(item.code);
          setEditType(value.id);
          setModalAction(true);
        }}
      />
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const createManual = () => {
    if (linkManual != "") {
      return (
        <a href={linkManual} target="_blank" title="Manual">
          <i className="fas fa-question-circle color-default" />
        </a>
      );
    } else {
      return "";
    }
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card className={classes.boxPrimary}>
            <CardHeader color="primary" icon className={classes.boxHead}>
              <h4 className={classes.boxTitle}>
                <i className="fas fa-file-alt color-primary" />
                {t("element.viewlist")} {createManual()}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6}>
                  <form
                    noValidate
                    onSubmit={(e) => {
                      e.preventDefault();
                      view(find);
                    }}
                  >
                    <TextField
                      id="search"
                      fullWidth
                      InputProps={{
                        onChange: (e) =>
                          setFind({ ...find, search: e.target.value, page: 1 }),
                        startAdornment: (
                          <InputAdornment position="start">
                            {t("element.search")} :
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </form>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      renderHeader(header[4]),
                      renderHeader(header[5]),
                      renderHeader(header[6]),
                      renderHeader(header[7]),
                    ]}
                    tableData={record}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[6]}
                    colorsColls={["primary"]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}></InputLabel>
                    <Select
                      value={find.perPage}
                      onChange={(e) => {
                        view({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                        setFind({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      {cboPerPage &&
                        cboPerPage.map((item) => (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {t(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={8}
                  lg={9}
                  className={classes.right}
                >
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    count={pageTotal}
                    showFirstButton
                    showLastButton
                    page={pageNo}
                    onChange={(e, p) => {
                      view({ ...find, page: p });
                      setFind({ ...find, page: p });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderEdit = () => {
    return (
      <div>
        {renderInfo()}
        <GridContainer>
          <GridItem xs={12} className={classes.right}>
            <Button color="success" onClick={edit}>
              <SaveIcon /> {t("element.save")}
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardBody>
                <div className="fileinput text-center">
                  <div
                    className={"thumbnail"}
                    style={{ maxWidth: "100%", textAlign: "center" }}
                  >
                    <img src={attr.filepic} />
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={8}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText={t(`${mod}.id`)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: attr.id,
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText={t(`${mod}.name`)}
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.name,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="บริษัท"
                  id="com_name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.com_name,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="เลขที่ผู้เสียภาษี"
                  id="com_id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.com_id,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="เบอร์โทร"
                  id="com_tel"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.com_tel,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="อีเมล"
                  id="com_email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.com_email,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="ที่อยู่"
                  id="address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.address,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  success={true}
                  labelText="Line Status"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: attr.line_status,
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  success={true}
                  labelText="Line E-mail"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: attr.line_email,
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  error={true}
                  labelText="วันสมัคร"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: Func.dateTimeDisplay(attr.date_create),
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12}></GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={attr.enable === "Y"}
                        name="enable"
                        tabIndex={-1}
                        onChange={checkChange}
                        checkedIcon={
                          <CheckIcon className={classes.checkedIcon} />
                        }
                        icon={<CheckIcon className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={t(`${prefix}.enable`)}
                  />
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderModalAction = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalAction}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalAction(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-edit-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalAction(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            {attr.name} ::{" "}
            {editType == "edit"
              ? "Edit"
              : editType == "credit"
              ? "Credit" + " " + Func.numberDisplay(attr.balance) + " $"
              : editType == "point"
              ? "Point" + " " + Func.numberDisplay(attr.point) + " $"
              : null}
          </h4>
        </DialogTitle>
        <DialogContent id="modal-edit" className={classes.modalBody}>
          {editType == "edit" ? (
            renderEdit()
          ) : editType == "credit" ? (
            <Credit id={id} open={open} />
          ) : editType == "point" ? (
            <MemberPoint id={id} open={open} />
          ) : null}
        </DialogContent>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderLoading()}
      {renderModalAction()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(Member);
