import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import Login from "views/Authen/Login";
import GoogleAuthen from "views/Authen/GoogleAuthen";
import login from "assets/img/login.jpg";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const wrapper = React.createRef();
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    return function cleanup() {};
  });
  const getActiveRoute = (routes) => {
    let activeRoute = "-";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  return (
    <div>
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + login + ")" }}
        >
          <Switch>
            <Route
              path="/auth/authenticator"
              component={GoogleAuthen}
              key={0}
            />
            <Route path="/auth/login" component={Login} key={0} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
        </div>
      </div>
    </div>
  );
}
