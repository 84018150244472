/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AlertIcon from "@material-ui/icons/AddAlert";
import DescriptionIcon from "@material-ui/icons/Description";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "../../services/Request";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  user_pass: "",
  user_pass_again: "",
};

/* ========== ZONE Function ========== */
function ChangePassword({ t }) {
  const mod = "employee";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);

  const [attr, setAttr] = useState(initAttr);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    profile();
  }, []);

  const changeTab = (event, value) => {
    setTab(value);
  };

  const profile = () => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "profile", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        setLoading(false);
      }
    });
  };

  const editPass = () => {
    if (password !== passwordAgain) return;

    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);

          PostData(mod, "editpass", {
            code: attr.code,
            data: {
              user_pass: password,
            },
          }).then((result) => {
            setAlert(null);
            if (result.status === "OK") {
              setPassword("");
              setPasswordAgain("");

              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t(`${mod}.changepass`),
                tabIcon: DescriptionIcon,
                tabContent: renderChangePass(),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderChangePass = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomInput
              error={password !== passwordAgain}
              labelText={t(`${mod}.user_pass`)}
              id="password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (e) => {
                  setPassword(e.target.value);
                },
                type: "password",
                value: password,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              error={password !== passwordAgain}
              labelText={t(`${mod}.user_pass_again`)}
              id="password_again"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (e) => {
                  setPasswordAgain(e.target.value);
                },
                type: "password",
                value: passwordAgain,
              }}
            />
          </GridItem>
          <GridItem xs={6}></GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} className={classes.right}>
            <Button color="success" onClick={editPass}>
              <SaveIcon /> {t("element.save")}
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderEdit()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(ChangePassword);
