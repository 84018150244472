import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Line, Bar } from "react-chartjs-2";
import { NavLink } from "react-router-dom";

// react plugin for creating charts

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import RobotIcon from "@material-ui/icons/Android";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardText from "components/Card/CardText.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

const useStyles = makeStyles(styles);

var Chartist = require("chartist");
var delays = 80,
  durations = 500;
var delays2 = 80,
  durations2 = 500;

function Dashboard({ t }) {
  const mod = "dashboard";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);

  const [sumMember, setSumMember] = useState("0");
  const [sumDeposit, setSumDeposit] = useState("0");
  const [sumWithdraw, setSumWithdraw] = useState("0");
  const [sumProfit, setSumProfit] = useState("0");
  const [sumSale, setSumSale] = useState("0");
  const [sumRobot, setSumRobot] = useState("0");
  const [robot, setRobot] = useState([]);
  const [order, setOrder] = useState([]);
  const [deposit, setDeposit] = useState([]);
  const [withdraw, setWithdraw] = useState([]);
  const [memberLastest, setMemberLastest] = useState([]);
  const [adminLogin, setAdminLogin] = useState([]);
  const [adminLogout, setAdminLogout] = useState([]);
  const [chartLabel, setChartLabel] = useState([]);
  const [chartProfit, setChartProfit] = useState([]);
  const [chartTopup, setChartTopup] = useState([]);
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, []);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setMenu(result.menu);

        setSumDeposit(result.summary.deposit);
        setSumWithdraw(result.summary.withdraw);
        setSumProfit(result.summary.profit);
        setSumSale(result.summary.sale);
        setSumRobot(result.summary.robot);
        setSumMember(result.summary.member);

        setChartLabel(result.barchart.label);

        setChartProfit([
          {
            label: `Total`,
            data: result.linechart.total,
            fill: false,
            backgroundColor: "rgba(135,206,250, 0.2)",
            borderColor: "rgba(135,206,250, 1.0)",
            cubicInterpolationMode: "monotone",
            tension: 0.4,
          },
          {
            label: `Signal`,
            data: result.linechart.signal,
            fill: false,
            backgroundColor: "rgba(0, 255, 64, 0.2)",
            borderColor: "rgba(0, 255, 64, 1.0)",
            cubicInterpolationMode: "monotone",
            tension: 0.4,
          },
          {
            label: `Tool`,
            data: result.linechart.tool,
            fill: false,
            backgroundColor: "rgba(233, 22, 80, 0.2)",
            borderColor: "rgba(233, 22, 80, 1.0)",
            cubicInterpolationMode: "monotone",
            tension: 0.4,
          },
        ]);

        setChartTopup([
          {
            label: `All`,
            data: result.barchart.all,
            fill: true,
            backgroundColor: "rgba(0, 128, 255, 0.6)",
            borderColor: "rgba(0, 128, 255, 1.0)",
            cubicInterpolationMode: "monotone",
            tension: 0.4,
          },
          {
            label: `T/P`,
            data: result.barchart.tp,
            fill: true,
            backgroundColor: "rgba(50,205,50, 0.6)",
            borderColor: "rgba(50,205,50, 1.0)",
            cubicInterpolationMode: "monotone",
            tension: 0.4,
          },
          {
            label: `S/L`,
            data: result.barchart.sl,
            fill: true,
            backgroundColor: "rgba(220,20,60, 0.6)",
            borderColor: "rgba(220,20,60, 1.0)",
            cubicInterpolationMode: "monotone",
            tension: 0.4,
          },
        ]);

        result.robot.map((item, i) => {
          setRobot((robot) => [
            ...robot,
            [
              btnOpen("robotauto", item),
              <span>
                <img src={item.member_pic} width="20" /> {item.member_name}
              </span>,
              <span>
                <img src={item.product_pic} width="20" /> {item.product_name}
              </span>,
              item.cat_name,
              Func.dateDisplay(item.date_create),
              displayExpire(item),
              Func.checkDisplay(item.enable),
            ],
          ]);
        });

        result.order.map((item, i) => {
          setOrder((order) => [
            ...order,
            [
              btnOpen("saleorder", item),
              <span>
                <img src={item.member_pic} width="20" /> {item.member_name}
              </span>,
              <span>
                <img src={item.product_pic} width="20" /> {item.product_name}
              </span>,
              Func.numberDisplay(item.amount),
              Func.numberDisplay(item.vat),
              Func.numberDisplay(item.total),
              Func.dateTimeDisplay(item.date_create),
              displayStatus(item),
            ],
          ]);
        });

        result.deposit.map((item, i) => {
          setDeposit((deposit) => [
            ...deposit,
            [
              <span>
                <img src={item.member_pic} width="20" /> {item.member_name}
              </span>,
              Func.numberDisplay(item.amount),
              Func.dateTimeDisplay(item.date_create),
              displayStatus(item),
            ],
          ]);
        });

        result.withdraw.map((item, i) => {
          setWithdraw((withdraw) => [
            ...withdraw,
            [
              <span>
                <img src={item.member_pic} width="20" /> {item.member_name}
              </span>,
              Func.numberDisplay(item.amount),
              Func.dateTimeDisplay(item.date_create),
              displayStatus(item),
            ],
          ]);
        });

        result.member.map((item, i) => {
          setMemberLastest((memberLastest) => [
            ...memberLastest,
            [
              <span>
                <img src={item.member_pic} width="20" /> {item.member_name}
              </span>,
              Func.dateTimeDisplay(item.date_create),
            ],
          ]);
        });

        result.lastlogin.map((item, i) => {
          setAdminLogin((adminLogin) => [
            ...adminLogin,
            [item.user_create, Func.dateTimeDisplay(item.date_create)],
          ]);
        });

        result.lastlogin.map((item, i) => {
          setAdminLogout((adminLogout) => [
            ...adminLogout,
            [item.user_create, Func.dateTimeDisplay(item.date_create)],
          ]);
        });
      }

      setLoading(false);
    });
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.status_color}`}>
        <i className={item.status_icon} /> {item.status_name}
      </code>
    );
  };

  const btnOpen = (type, item) => {
    return (
      <NavLink to={`/admin/${type}/${item.code}`}>
        <Button
          color="primary"
          simple
          className={classes.actionButton + " " + classes.actionButtonRound}
        >
          {item.id}
        </Button>
      </NavLink>
    );
  };

  const displayExpire = (item) => {
    return (
      <span
        className={
          item.status_expire === "Y" ? "color-success" : "color-danger"
        }
      >
        {Func.dateDisplay(item.date_expire)}
      </span>
    );
  };

  const setupBarChart = {
    options: {
      seriesBarDistance: 10,
      axisX: {
        showGrid: false,
      },
      height: "300px",
    },
    responsiveOptions: [
      [
        "screen and (max-width: 640px)",
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0];
            },
          },
        },
      ],
    ],
    animation: {
      draw: function (data) {
        if (data.type === "bar") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays2,
              dur: durations2,
              from: 0,
              to: 1,
              easing: "ease",
            },
          });
        }
      },
    },
  };

  const setupLineChart = {
    options: {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 10,
      }),
      axisY: {
        showGrid: true,
        offset: 40,
      },
      axisX: {
        showGrid: false,
      },
      showPoint: true,
      height: "300px",
    },
    animation: {
      draw: function (data) {
        if (data.type === "line" || data.type === "area") {
          data.element.animate({
            d: {
              begin: 600,
              dur: 700,
              from: data.path
                .clone()
                .scale(1, 0)
                .translate(0, data.chartRect.height())
                .stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint,
            },
          });
        } else if (data.type === "point") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays,
              dur: durations,
              from: 0,
              to: 1,
              easing: "ease",
            },
          });
        }
      },
    },
  };

  const renderStatus = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={4} lg={2}>
          <Card>
            <CardHeader icon>
              <CardIcon color="info">
                <TouchAppIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Sale</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>฿ {sumSale}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={2}>
          <Card>
            <CardHeader icon>
              <CardIcon color="success">
                <AddCircleIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Deposit</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>
                <i className="fal fa-dollar-sign" /> {sumDeposit}
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={2}>
          <Card>
            <CardHeader icon>
              <CardIcon color="danger">
                <RemoveCircleIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Withdraw</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>
                <i className="fal fa-dollar-sign" /> {sumWithdraw}
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={2}>
          <Card>
            <CardHeader icon>
              <CardIcon color="primary">
                <MonetizationOnIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Profit</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>
                <i className="fal fa-dollar-sign" /> {sumProfit}
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={2}>
          <Card>
            <CardHeader icon>
              <CardIcon color="rose">
                <PersonAddIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Member</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>
                <i className="fal fa-user" /> {sumMember}
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={2}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <RobotIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Robot</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{sumRobot}</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderChart = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                การสั่งซื้อ <small>- 7 วันหลังสุด</small>
              </h4>
            </CardHeader>
            <CardBody>
              <Line
                height={100}
                options={{
                  responsive: true,
                  interaction: {
                    intersect: false,
                  },
                  scales: {
                    x: {
                      display: true,
                      title: {
                        display: true,
                      },
                    },
                    y: {
                      display: true,
                      title: {
                        display: true,
                      },
                      suggestedMin: 0,
                    },
                  },
                }}
                data={{
                  labels: chartLabel,
                  datasets: chartProfit,
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Take profit / Stop loss <small>- 7 วันหลังสุด</small>
              </h4>
            </CardHeader>
            <CardBody>
              <Bar
                height={100}
                options={{
                  responsive: true,
                  interaction: {
                    intersect: false,
                  },
                  scales: {
                    x: {
                      display: true,
                      title: {
                        display: true,
                      },
                    },
                    y: {
                      display: true,
                      title: {
                        display: true,
                      },
                      suggestedMin: 0,
                    },
                  },
                }}
                data={{
                  labels: chartLabel,
                  datasets: chartTopup,
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderTable = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary" text>
              <CardText color="primary">
                <h4 className={classes.cardTitleWhite}>รายการ Robot ล่าสุด</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Table
                hover
                tableHeaderColor="primary"
                tableHead={[
                  "#",
                  "Member",
                  "Product",
                  "Mode",
                  "Create",
                  "Expire",
                  "Status",
                ]}
                tableData={robot}
                customHeadClassesForCells={[0, 3, 4, 5, 6]}
                customHeadCellClasses={[
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                ]}
                customClassesForCells={[0, 3, 4, 5, 6]}
                customCellClasses={[
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.center,
                ]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>รายการขายล่าสุด</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Table
                hover
                tableHeaderColor="info"
                tableHead={[
                  "#",
                  "Member",
                  "Product",
                  "Amount",
                  "Vat",
                  "Total",
                  "Create",
                  "Status",
                ]}
                tableData={order}
                customHeadClassesForCells={[0, 3, 4, 5, 6, 7]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right,
                  classes.right,
                  classes.center,
                  classes.center,
                ]}
                customClassesForCells={[0, 3, 4, 5, 6, 7]}
                customCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right,
                  classes.right,
                  classes.center,
                  classes.center,
                ]}
                coloredColls={[3, 4, 5]}
                colorsColls={["primary", "danger", "success"]}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Latest Member</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Table
                hover
                tableHeaderColor="info"
                tableHead={["Member", "Date"]}
                tableData={memberLastest}
                customHeadClassesForCells={[1]}
                customHeadCellClasses={[classes.right]}
                customClassesForCells={[1]}
                customCellClasses={[classes.right]}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Admin, Last login</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Table
                hover
                tableHeaderColor="info"
                tableHead={["Admin", "Date"]}
                tableData={adminLogin}
                customHeadClassesForCells={[1]}
                customHeadCellClasses={[classes.right]}
                customClassesForCells={[1]}
                customCellClasses={[classes.right]}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Admin, Last logout</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Table
                hover
                tableHeaderColor="info"
                tableHead={["Admin", "Date"]}
                tableData={adminLogout}
                customHeadClassesForCells={[1]}
                customHeadCellClasses={[classes.right]}
                customClassesForCells={[1]}
                customCellClasses={[classes.right]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderButton = () => {
    return (
      <GridContainer>
        {menu.map((item) => (
          <GridItem xs={12} sm={4} md={3} lg={2} key={item.id}>
            <NavLink to={`/admin/${item.link}`}>
              <Button
                color={item.color}
                block
                style={{ justifyContent: "left" }}
              >
                <i className="fas fa-grip-vertical" /> {t(`menu.${item.id}`)}
              </Button>
            </NavLink>
          </GridItem>
        ))}
      </GridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <div>
      {renderStatus()}
      {renderButton()}
      {renderChart()}
      {renderTable()}
      {renderLoading()}
    </div>
  );
}

export default withNamespaces()(Dashboard);
