/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import UploadImage from "./UploadImage";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AlertIcon from "@material-ui/icons/AddAlert";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CloseIcon from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  name: "",
  surname: "",
  tel: "",
  email: "",
  user_name: "",
  user_pass: "",
  user_pass_again: "",
  task_code: "",
  image: "",
  googleauthen: "Y",
  enable: "Y",
};

/* ========== ZONE Function ========== */
function EditProfile({ t }) {
  const mod = "employee";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);

  const [attr, setAttr] = useState(initAttr);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [qrcode, setQrcode] = useState(``);
  const [googleKey, setGoogleKey] = useState("");
  const [keycode, setKeycode] = useState("");

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    profile();
  }, []);

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const profile = () => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "profile", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        setLoading(false);
      }
    });
  };

  const googleAuthen = (e) => {
    if (e.target.checked) {
      loadQrcode();
    } else {
      closeGoogleAuthen();
    }
  };

  const loadQrcode = () => {
    PostData(mod, "googleqrcode", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setQrcode(result.qrcode);
        setGoogleKey(result.key);
        setModal(true);
      }
    });
  };

  const openGoogleAuthen = () => {
    if (keycode == "") return;

    PostData(mod, "openauthen", {
      token: app.token,
      lang: app.lang,
      googlekey: googleKey,
      keycode: keycode,
    }).then((result) => {
      setModal(false);
      if (result.status === "OK") {
        setAttr({ ...attr, googleauthen: "Y" });
      }
    });
  };

  const closeGoogleAuthen = () => {
    PostData(mod, "closeauthen", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr({ ...attr, googleauthen: "N" });
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (!Func.verifyLength(attr.name, 1)) result = false;

    return result;
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "edit", {
              token: app.token,
              lang: app.lang,
              code: attr.code,
              data: {
                name: attr.name,
                surname: attr.surname,
                task_code: attr.task_code,
                email: attr.email,
                tel: attr.tel,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const uploadAvatar = (filedata) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setLoading(true);
          setAlert(null);

          PostData(mod, "uploadprofile", {
            token: app.token,
            lang: app.lang,
            filedata: filedata,
          }).then((result) => {
            setAlert(null);
            setLoading(false);

            if (result.status === "OK") {
              profile();
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("upload.upload")}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.info"),
                tabIcon: DescriptionIcon,
                tabContent: (
                  <div>
                    {renderInfo()}
                    <GridContainer>
                      <GridItem xs={12} className={classes.right}>
                        <Button color="success" onClick={edit}>
                          <SaveIcon /> {t("element.save")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                ),
              },
              {
                tabName: t(`${mod}.avatar`),
                tabIcon: AccountCircleIcon,
                tabContent: renderAvatar(),
              },
              // {
              //   tabName: t(`${mod}.2fa`),
              //   tabIcon: SecurityIcon,
              //   tabContent: renderGoogleAuthen(),
              // },
            ]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomInput
              error={!Func.verifyLength(attr.name, 1)}
              labelText={t(`${mod}.name`) + " *"}
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.name,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${mod}.surname`)}
              id="surname"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.surname,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${mod}.tel`)}
              id="tel"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.tel,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${mod}.email`)}
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.email,
              }}
            />
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderAvatar = () => {
    return (
      <Card profile>
        <p></p>
        <p></p>
        <CardAvatar profile>
          <img src={attr.image} />
        </CardAvatar>
        <CardBody profile>
          <UploadImage uploadData={uploadAvatar} />
        </CardBody>
      </Card>
    );
  };

  const renderGoogleAuthen = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={attr.googleauthen === "Y"}
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar,
                  }}
                  onChange={googleAuthen}
                />
              }
              classes={{
                label: classes.label,
              }}
              label="Google Authenticator"
            />
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderModalGoogle = () => {
    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modal}
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Google Authenticator</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <p>Scan by App " Google Authenticator "</p>
          <p>
            Download :{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=th&gl=US"
              target="_blank"
            >
              Android
            </a>{" "}
            |{" "}
            <a
              href="https://apps.apple.com/th/app/google-authenticator/id388497605"
              target="_blank"
            >
              IOS
            </a>
          </p>
          <img src={qrcode} width="150" />
          <CustomInput
            labelText="Code"
            id="keycode"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              onChange: (e) => {
                setKeycode(e.target.value);
              },
              value: keycode,
            }}
          />
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setModal(false)}>{t(`${mod}.cancel`)}</Button>
          <Button onClick={openGoogleAuthen} color="success">
            {t(`${mod}.open`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderEdit()}
      {renderLoading()}
      {renderModalGoogle()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(EditProfile);
