/*eslint-disable*/
import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import UploadSlide from "./UploadSlide";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "../../services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  name_th: "",
  name_en: "",
  url: "",
  sort: "",
  enable: "Y",
};

const initFind = {
  search: "",
  order_by: "tb.sort",
  order_sort: "asc",
  page: 1,
  perPage: 100,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function SlideShow({ t }) {
  const mod = "slideshow";
  const prefix = "attr";
  const classes = useStyles();

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [find, setFind] = useState(initFind);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const checkChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.checked ? "Y" : "N" });
  };

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        if (id === undefined) {
          view(find);
        } else if (id === "add") {
          setAttr(initAttr);
        } else {
          setTab(0);
          open();
        }
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setRecord([]);
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord(result.data);
      }
      setLoading(false);
    });
  };

  const open = () => {
    setAttr(initAttr);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (!Func.verifyLength(attr.name_th, 1)) result = false;

    return result;
  };

  const add = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "add", {
              data: {
                name_th: attr.name_th,
                name_en: attr.name_en == "" ? attr.name_th : attr.name_en,
                url: attr.url,
                sort: attr.sort,
                enable: attr.enable,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");
                window.location.assign(`/admin/${mod}/${result.code}`);
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "edit", {
              token: app.token,
              lang: app.lang,
              code: attr.code,
              data: {
                name_th: attr.name_th,
                name_en: attr.name_en == "" ? attr.name_th : attr.name_en,
                url: attr.url,
                sort: attr.sort,
                enable: attr.enable,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                open();
                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const del = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_delete_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "delete", {
            token: app.token,
            lang: app.lang,
            code: item.code,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              view(find);
              showNotify(t("alert.deletecomplete"), "success");
            } else {
              showNotify(t("alert.deleteerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_delete_msg")} #{item.code}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const uploadImage = (filedata) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "upload", {
            code: attr.code,
            token: app.token,
            lang: app.lang,
            filedata: filedata,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              open();
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("upload.upload")}
      </SweetAlert>
    );
  };

  const renderViewList = () => {
    return (
      <>
        <GridContainer>
          {record.map((item) => (
            <GridItem xs={12} sm={12} md={4} lg={3} key={item.code}>
              <Card product className={classes.cardHover}>
                <CardHeader
                  image
                  className={classes.cardHeaderHover}
                  style={{ background: "white" }}
                >
                  <img src={item.image} />
                </CardHeader>
                <CardBody>
                  <div className={classes.cardHoverUnder}>
                    <Tooltip
                      id="tooltip-top"
                      title={t(`element.edit`)}
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <NavLink to={`/admin/${mod}/${item.code}`}>
                        <Button color="success" simple justIcon>
                          <EditIcon className={classes.underChartIcons} />
                        </Button>
                      </NavLink>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-top"
                      title={t(`element.delete`)}
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        color="danger"
                        simple
                        justIcon
                        onClick={() => del(item)}
                      >
                        <DeleteIcon className={classes.underChartIcons} />
                      </Button>
                    </Tooltip>
                  </div>
                  <h4 className={classes.cardProductTitle}>{item.name_th}</h4>
                </CardBody>
                <CardFooter product>
                  <div className={classes.price}></div>
                  <div className={`${classes.stats} ${classes.productStats}`}>
                    {Func.checkDisplay(item.enable)}
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          ))}
        </GridContainer>

        <NavLink to={`/admin/${mod}/add`}>
          <Fab
            aria-label="Add"
            style={{
              position: "absolute",
              bottom: "16px",
              right: "16px",
            }}
            color={"primary"}
          >
            <AddIcon />
          </Fab>
        </NavLink>
      </>
    );
  };

  const renderAdd = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody className={classes.right}>
              <NavLink to={`/admin/${mod}`}>
                <Button color="warning">
                  <ArrowBackIcon /> {t("element.back")}
                </Button>
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.adddata")}</h4>
            </CardHeader>
            <CardBody>
              {renderInfo()}

              <GridContainer>
                <GridItem xs={12} className={classes.right}>
                  <Button color="success" onClick={add}>
                    <SaveIcon /> {t("element.save")}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>{attr.name_th}</h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <NavLink to={`/admin/${mod}`}>
                    <Button color="warning">
                      <ArrowBackIcon /> {t("element.back")}
                    </Button>
                  </NavLink>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.info"),
                tabIcon: DescriptionIcon,
                tabContent: (
                  <div>
                    {renderInfo()}
                    <GridContainer>
                      <GridItem xs={12} className={classes.right}>
                        <Button color="success" onClick={edit}>
                          <SaveIcon /> {t("element.save")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                ),
              },
              {
                tabName: t("upload.avatar"),
                tabIcon: DescriptionIcon,
                tabContent: renderImage(),
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12}>{renderDetail()}</GridItem>
      </GridContainer>
    );
  };

  const renderImage = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardBody>
              <div className="fileinput text-center">
                <div
                  className={"thumbnail"}
                  style={{ maxWidth: "100%", textAlign: "center" }}
                >
                  <img src={attr.image} alt="..." />
                </div>
              </div>
              <UploadSlide uploadData={uploadImage} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6} lg={4}>
            <CustomInput
              labelText={
                <div>
                  {t(`${prefix}.name_th`)}
                  <span className={classes.colorDanger}> *</span>
                </div>
              }
              id="name_th"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.name_th,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} lg={4}>
            <CustomInput
              labelText={t(`${prefix}.name_en`)}
              id="name_en"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.name_en,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} lg={4}>
            <CustomInput
              labelText={t(`${prefix}.sort`)}
              id="sort"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.sort,
                type: "number",
              }}
              alignCenter
            />
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText="URL"
              id="url"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.url,
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attr.enable === "Y"}
                    name="enable"
                    tabIndex={-1}
                    onChange={checkChange}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={t("attr.enable")}
              />
            </div>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderPage = () => {
    if (id === undefined) {
      return renderViewList();
    } else if (id === "add") {
      return renderAdd();
    } else {
      return renderEdit();
    }
  };

  const renderList = () => {
    // return renderBonusBetween("percent");

    switch (attr.length_type) {
      case "PRICE":
        return renderBonusPrice();
      case "PERCENT":
        return renderBonusPercent();
      case "TIME":
        return renderBonusTime("price");
      case "TIMEPC":
        return renderBonusTime("percent");
      case "AMOUNT":
        return renderBonusAmount("price");
      case "AMOUNTPC":
        return renderBonusAmount("percent");
      case "BETWEEN":
        return renderBonusBetween("price");
      case "BETWEENPC":
        return renderBonusBetween("percent");
      default:
        return <div></div>;
    }
  };

  const renderBonusPrice = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${prefix}.bonus_price`)}
              id="bonus_price"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "0.00",
                onChange: inputChange,
                value: attr.bonus_price,
                type: "number",
              }}
              alignCenter
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Button color="success" onClick={editPrice} size={"lg"}>
              <SaveIcon /> {t("element.save")}
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderBonusPercent = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${prefix}.bonus_percent`)}
              id="bonus_percent"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "0.00",
                onChange: inputChange,
                value: attr.bonus_percent,
                type: "number",
              }}
              alignCenter
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Button color="success" onClick={editPercent} size={"lg"}>
              <SaveIcon /> {t("element.save")}
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderBonusTime = (type) => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12} sm={6} md={4} lg={3}>
                <CustomInput
                  labelText={t(`${mod}.time_start`)}
                  id="time_start"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "00:00",
                    onChange: (e) =>
                      setField({ ...field, time_start: e.target.value }),
                    value: field.time_start,
                  }}
                  alignCenter
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} lg={3}>
                <CustomInput
                  labelText={t(`${mod}.time_stop`)}
                  id="time_stop"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "00:00",
                    onChange: (e) =>
                      setField({ ...field, time_stop: e.target.value }),
                    value: field.time_stop,
                  }}
                  alignCenter
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} lg={3}>
                <CustomInput
                  labelText={t(`${mod}.bonus_${type}`)}
                  id="amount"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "0.00",
                    onChange: (e) =>
                      setField({ ...field, amount: e.target.value }),
                    value: field.amount,
                    type: "number",
                  }}
                  alignCenter
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} lg={3}>
                <Button color="success" onClick={addTime} size={"lg"}>
                  <SaveIcon /> {t("element.add")}
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
        <GridItem xs={12}>
          <hr />
        </GridItem>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${mod}.num`),
              t(`${mod}.time_start`),
              t(`${mod}.time_stop`),
              t(`${mod}.bonus_${type}`),
              "",
            ]}
            tableData={listTime}
            customHeadClassesForCells={[0, 1, 2, 3, 4]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.center,
            ]}
            coloredColls={[3]}
            colorsColls={["primary"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderBonusAmount = (type) => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12} sm={6} md={4} lg={3}>
                <CustomInput
                  labelText={t(`${prefix}.deposit_fix`)}
                  id="deposit_amount"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "0.00",
                    onChange: (e) =>
                      setField({ ...field, deposit_amount: e.target.value }),
                    value: field.deposit_amount,
                    type: "number",
                  }}
                  alignCenter
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} lg={3}>
                <CustomInput
                  labelText={t(`${mod}.bonus_${type}`)}
                  id="amount"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "0.00",
                    onChange: (e) =>
                      setField({ ...field, amount: e.target.value }),
                    value: field.amount,
                    type: "number",
                  }}
                  alignCenter
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} lg={3}>
                <Button color="success" onClick={addAmount} size={"lg"}>
                  <SaveIcon /> {t("element.add")}
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
        <GridItem xs={12}>
          <hr />
        </GridItem>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${mod}.num`),
              t(`${mod}.deposit_fix`),
              t(`${mod}.bonus_${type}`),
              "",
            ]}
            tableData={listAmount}
            customHeadClassesForCells={[0, 1, 2, 3]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.right,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 2, 3]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.right,
              classes.center,
            ]}
            coloredColls={[2]}
            colorsColls={["primary"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderBonusBetween = (type) => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12} sm={6} md={4} lg={3}>
                <CustomInput
                  labelText={t(`${prefix}.deposit_amount`)}
                  id="deposit_amount"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "0.00",
                    onChange: (e) =>
                      setField({ ...field, deposit_amount: e.target.value }),
                    value: field.deposit_amount,
                    type: "number",
                  }}
                  alignCenter
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} lg={3}>
                <CustomInput
                  labelText={t(`${prefix}.deposit_stop`)}
                  id="deposit_stop"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "0.00",
                    onChange: (e) =>
                      setField({ ...field, deposit_stop: e.target.value }),
                    value: field.deposit_stop,
                    type: "number",
                  }}
                  alignCenter
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} lg={3}>
                <CustomInput
                  labelText={t(`${mod}.bonus_${type}`)}
                  id="amount"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "0.00",
                    onChange: (e) =>
                      setField({ ...field, amount: e.target.value }),
                    value: field.amount,
                    type: "number",
                  }}
                  alignCenter
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} lg={3}>
                <Button color="success" onClick={addBetween} size={"lg"}>
                  <SaveIcon /> {t("element.add")}
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
        <GridItem xs={12}>
          <hr />
        </GridItem>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${mod}.num`),
              t(`${mod}.deposit_amount`),
              t(`${mod}.deposit_stop`),
              t(`${mod}.bonus_${type}`),
            ]}
            tableData={listBetween}
            customHeadClassesForCells={[0, 1, 2, 3, 4]}
            customHeadCellClasses={[
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4]}
            customCellClasses={[
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.center,
            ]}
            coloredColls={[3]}
            colorsColls={["primary"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_update}</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderPage()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(SlideShow);
