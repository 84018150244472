import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { getOrientation } from "get-orientation/browser";
import {
  getCroppedImg,
  getRotatedImage,
} from "components/UploadImage/CanvasUtil";
import { styles } from "components/UploadImage/UploadStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Resizer from "react-image-file-resizer";

// @material-ui/icons
import ImageIcon from "@material-ui/icons/Image";
import CloseIcon from "@material-ui/icons/Close";
import UploadIcon from "@material-ui/icons/Publish";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import i18n from "components/Translation/Translate.js";

const ORIENTATION_TO_ANGLE = {
  "3": 180,
  "6": 90,
  "8": -90,
};

const UploadImage = ({ classes, uploadData }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [modal, setModal] = useState(true);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const upload = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );

      fetch(croppedImage)
        .then((res) => res.blob())
        .then(async (data) => {
          try {
            const image = await resizeFile(data);
            uploadData(image);
            cancel();
          } catch (err) {
            console.log(err);
          }
        });
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  const cancel = () => {
    setImageSrc(null);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      // console.log(imageDataUrl)

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }

      setImageSrc(imageDataUrl);
    }
  };

  const readFile = (file) => {
    return new Promise((resolve) => {
      // console.log(file)
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  return (
    <div>
      {imageSrc ? (
        <div>
          <Dialog
            open={modal}
            maxWidth={"md"}
            keepMounted
            fullWidth
            disableBackdropClick
            onClose={() => setModal(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <h4 className={classes.modalTitle}> </h4>
            </DialogTitle>
            <DialogContent
              className={classes.modalBody}
              style={{ minHeight: "500px" }}
            >
              <GridContainer
                className={classes.cropContainer}
                style={{ margin: "0" }}
              >
                <GridItem xs={12}>
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer style={{ marginTop: "20px" }}>
                <GridItem xs={6}>
                  <Typography id="image-zoom" gutterBottom>
                    {i18n.t("upload.zoom")}
                  </Typography>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    scale={(x) => x ** 100}
                    onChange={(e, zoom) => setZoom(zoom)}
                    aria-labelledby="image-zoom"
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Typography id="image-rotation" gutterBottom>
                    {i18n.t("upload.rotation")}
                  </Typography>
                  <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    scale={(x) => x ** 100}
                    onChange={(e, rotation) => setRotation(rotation)}
                    aria-labelledby="image-rotation"
                  />
                </GridItem>
              </GridContainer>

              <GridContainer style={{ marginTop: "20px" }}>
                <GridItem xs={6}>
                  <Button round color="danger" onClick={cancel}>
                    <CloseIcon /> {i18n.t("upload.cancel")}
                  </Button>
                </GridItem>
                <GridItem xs={6} style={{ textAlign: "right" }}>
                  <Button round color="info" onClick={upload}>
                    <UploadIcon /> {i18n.t("upload.upload")}
                  </Button>
                </GridItem>
              </GridContainer>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <GridContainer>
          <GridItem xs={12} style={{ textAlign: "center" }}>
            <input
              accept="image/*"
              className={classes.input}
              style={{ display: "none" }}
              id="file-open"
              multiple
              type="file"
              onChange={onFileChange}
            />
            <label htmlFor="file-open">
              <Button round color="info" component="span">
                <ImageIcon /> {i18n.t("upload.browse")}
              </Button>
            </label>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
};

export default withStyles(styles)(UploadImage);
