import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardFooter from "components/Card/CardFooter.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import i18n from "components/Translation/Translate.js";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import AddAlert from "@material-ui/icons/AddAlert";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

import { PostSecure } from "services/Request";
import { setToken } from "redux/actions/AppStateAction";
import { setUser } from "redux/actions/UserAction";
import { setPerm } from "redux/actions/PermAction";
import { useDispatch } from "react-redux";
import { usePageName } from "react-page-name";
import { withNamespaces } from "react-i18next";

import styles from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js";

const useStyles = makeStyles(styles);

function GoogleAuthen({ t }) {
  const mod = "authen";
  const classes = useStyles();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.appState);
  const users = useSelector((state) => state.user);
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [googleAuthen, setGoogleAuthen] = useState("");
  const [alert, setAlert] = useState(null);
  const [qrcode, setQrcode] = useState("");
  const [googleKey, setGoogleKey] = useState("");
  const [openQR, setOpenQR] = useState("N");
  const txtUser = useRef(null);
  const txtSecure = useRef(null);

  usePageName(t("app.name") + " :: Authenticator");

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    loadQrcode();

    let id = setTimeout(function () {
      setCardAnimation("");
      txtSecure.current.select();
    }, 700);

    return function cleanup() {
      window.clearTimeout(id);
    };
  }, []);

  const loadQrcode = () => {
    PostSecure(mod, "googleqrcode", {
      token: app.token,
      lang: app.lang,
      user: users.code,
    }).then((result) => {
      if (result.status === "OK") {
        setQrcode(result.qrcode);
        setGoogleKey(result.key);
        setOpenQR("Y");
      } else if (result.status === "HIDE") {
        setOpenQR("N");
      }
    });
  };

  const checkGooglAuthen = (event) => {
    if (googleAuthen == "") return;

    setAlert(null);
    event.preventDefault();

    PostSecure("authen", "googleauthen", {
      lang: app.lang,
      key: googleAuthen,
    }).then((result) => {
      if (result.status === "OK") {
        dispatch(setToken(result.data.token));
        dispatch(setUser(result.data));
        dispatch(
          setPerm({
            menu: result.menu,
            perm: result.perm,
          })
        );

        window.location.replace("/admin/dashboard");
      } else {
        showError(t(`${mod}.${result.msg}`));
      }
    });
  };

  const back = () => {
    dispatch(setToken(""));
    dispatch(setUser({}));
    window.location.replace("/auth/login");
  };

  const showError = (msg) => {
    setAlert(
      <CardFooter className={classes.justifyContentCenter}>
        <SnackbarContent message={msg} icon={AddAlert} color="danger" />
      </CardFooter>
    );
  };

  return (
    <div className={classes.container}>
      <form onSubmit={checkGooglAuthen}>
        <Card
          profile
          className={classes.customCardClass + " " + classes[cardAnimaton]}
          style={{ paddingTop: "5px" }}
        >
          <CardAvatar profile className={classes.cardAvatar}>
            <img src={users.image} />
          </CardAvatar>
          <CardBody profile>
            <h4 className={classes.cardTitle}>{users.user}</h4>
            {openQR == "Y" ? (
              <div>
                <div>
                  <br />
                  <img src={qrcode} width="150" />
                </div>
                <div style={{ color: "#999999" }}>" Google Authenticator "</div>
              </div>
            ) : null}
            <CustomInput
              labelText="Code"
              id="googleauthen"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                inputRef: txtSecure,
                endAdornment: (
                  <InputAdornment position="end">
                    <VpnKeyIcon className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                onChange: (e) => {
                  setGoogleAuthen(e.target.value);
                },
                value: googleAuthen,
              }}
              alignCenter
            />
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button
              type="submit"
              color="danger"
              round
              onClick={checkGooglAuthen}
              block
            >
              <VerifiedUserIcon /> {t(`${mod}.confirm`)}
            </Button>
          </CardFooter>
          {alert}
        </Card>
      </form>
      <div style={{ textAlign: "center", padding: "10px" }}>
        <Button
          color="warning"
          simple
          style={{ color: "#cccccc" }}
          onClick={back}
        >
          <i className="fas fa-arrow-circle-left" /> {t(`${mod}.backtologin`)}
        </Button>
      </div>
    </div>
  );
}

export default withNamespaces()(GoogleAuthen);
