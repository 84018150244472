/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import UploadImage from "./UploadImage";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  name_th: "",
  name_en: "",
  detail_th: "",
  detail_en: "",
  url: "",
  sort: "",
  enable: "Y",
};

const initFind = {
  search: "",
  order_by: "tb.sort",
  order_sort: "asc",
  page: 1,
  perPage: 100,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function ManualList({ t }) {
  const mod = "manual_list";
  const prefix = "attr";
  const classes = useStyles();

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [find, setFind] = useState(initFind);
  const [modal, setModal] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [flag, setFlag] = useState("add");

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const checkChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.checked ? "Y" : "N" });
  };

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setImageData(null);
    setRecord([]);
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord(result.data);
      }
      setLoading(false);
    });
  };

  const open = () => {
    setAttr(initAttr);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (imageData === null) result = false;

    return result;
  };

  const add = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "add", {
              token: app.token,
              lang: app.lang,
              id: id,
              filedata: imageData,
              data: {
                manual_code: id,
                name_th: attr.name_th,
                name_en: attr.name_en == "" ? attr.name_th : attr.name_en,
                detail_th: attr.detail_th,
                detail_en:
                  attr.detail_en == "" ? attr.detail_th : attr.detail_en,
                sort: attr.sort,
                enable: attr.enable,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");

                setModal(false);
                view(find);
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const edit = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "edit", {
            token: app.token,
            lang: app.lang,
            code: attr.code,
            filedata: imageData === null ? "" : imageData,
            data: {
              name_th: attr.name_th,
              name_en: attr.name_en == "" ? attr.name_th : attr.name_en,
              detail_th: attr.detail_th,
              detail_en: attr.detail_en == "" ? attr.detail_th : attr.detail_en,
              sort: attr.sort,
              enable: attr.enable,
            },
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              showNotify(t("alert.editcomplete"), "success");

              setModal(false);
              view(find);
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const del = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_delete_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "delete", {
            token: app.token,
            lang: app.lang,
            code: item.code,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              view(find);
              showNotify(t("alert.deletecomplete"), "success");
            } else {
              showNotify(t("alert.deleteerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_delete_msg")} #{item.code}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const uploadImage = (filedata) => {
    setImageData(filedata);
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Button
            color="info"
            onClick={() => {
              setImageData(null);
              setFlag("add");
              setModal(true);
            }}
          >
            <AddIcon /> {t("element.add")}
          </Button>
        </GridItem>
        {record.map((item) => (
          <GridItem xs={12} sm={12} md={4} lg={3} key={item.code}>
            <Card product className={classes.cardHover}>
              <CardHeader
                image
                className={classes.cardHeaderHover}
                style={{ background: "white" }}
              >
                <img src={item.image} />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title={t(`element.edit`)}
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      color="success"
                      simple
                      justIcon
                      onClick={() => {
                        setAttr(item);
                        setFlag("edit");
                        setModal(true);
                      }}
                    >
                      <EditIcon className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title={t(`element.delete`)}
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      color="danger"
                      simple
                      justIcon
                      onClick={() => del(item)}
                    >
                      <DeleteIcon className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardProductTitle}>{item.name_th}</h4>
              </CardBody>
              <CardFooter product>
                <div className={classes.price}></div>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  {Func.checkDisplay(item.enable)}
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    );
  };

  const renderImage = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <div className="fileinput text-center">
            <div
              className={"thumbnail"}
              style={{ maxWidth: "100%", textAlign: "center" }}
            >
              {imageData === null ? (
                attr.image === undefined ? null : (
                  <img src={attr.image} style={{ width: "100%" }} />
                )
              ) : (
                <img src={imageData} style={{ width: "100%" }} />
              )}
            </div>
          </div>
          <UploadImage uploadData={uploadImage} />
        </GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${prefix}.name_th`)}
              id="name_th"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.name_th,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${prefix}.name_en`)}
              id="name_en"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.name_en,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`attr.detail_th`)}
              id="detail_th"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                multiline: true,
                value: attr.detail_th,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`attr.detail_en`)}
              id="detail_en"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                multiline: true,
                value: attr.detail_en,
              }}
            />
          </GridItem>
          <GridItem xs={12} className={classes.left}>
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attr.enable === "Y"}
                    name="enable"
                    tabIndex={-1}
                    onChange={checkChange}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={t("attr.enable")}
              />
            </div>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderModalAdd = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modal}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-add-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            {flag === "add" ? t(`element.add`) : t(`element.edit`)}
          </h4>
        </DialogTitle>
        <DialogContent id="modal-add" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12} sm={7}>
              {renderInfo()}
            </GridItem>
            <GridItem xs={12} sm={5}>
              {renderImage()}
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {flag === "add" ? (
            <Button onClick={add} color="success">
              <SaveIcon /> {t(`element.save`)}
            </Button>
          ) : (
            <Button onClick={edit} color="success">
              <SaveIcon /> {t(`element.save`)}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModalAdd()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(ManualList);
