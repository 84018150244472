// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import SettingsIcon from "@material-ui/icons/Settings";
import DescriptionIcon from "@material-ui/icons/Description";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AndroidIcon from "@material-ui/icons/Android";
import PersonIcon from "@material-ui/icons/Person";
import VpsIcon from "@material-ui/icons/Build";
import SignalIcon from "@material-ui/icons/SignalCellular3Bar";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";

// views
import Dashboard from "views/Dashboard/Dashboard";
import Permission from "views/Permission/Permission";
import Menu from "views/Tool/Menu";
import Employee from "views/Employee/Employee";
import Task from "views/Employee/Task";
import Bank from "views/Setting/Bank";
import Log from "views/Tool/Log";
import EditProfile from "views/Employee/EditProfile";
import ChangePassword from "views/Employee/ChangePassword";
import WalletSetting from "views/Setting/WalletSetting";
import Manual from "views/Setting/Manual";
import Member from "views/Member/Member";
import Symbol from "views/Forex/Symbol";
import RpAllLog from "views/Report/RpAllLog";
import RpStat from "views/Report/RpStat";
import SlideShow from "views/Setting/SlideShow";
import Product from "views/Product/Product";
import Signal from "views/Forex/Signal";
import SaleOrder from "views/Sale/SaleOrder";
import Category from "views/Product/Category";
import DayTrade from "views/Forex/DayTrade";
import TraderTool from "views/Forex/TraderTool";
import RobotAuto from "views/Forex/RobotAuto";
import Broker from "views/Setting/Broker";
import ExpertAdvisor from "views/Product/ExpertAdvisor";
import Coupon from "views/Product/Coupon";
import Seminar from "views/Seminar/Seminar";
import VPS from "views/Member/VPS";

var dashRoutes = [
  {
    id: "dashboard",
    path: "/dashboard",
    name: "menu.dashboard",
    rtlName: "",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
    color: "#FFFFFF",
    weight: "500",
  },
  {
    id: "robotauto",
    path: "/robotauto",
    name: "menu.robot",
    rtlName: "",
    icon: AndroidIcon,
    component: RobotAuto,
    layout: "/admin",
    color: "#FFFFFF",
    weight: "500",
  },
  {
    id: "member",
    path: "/member",
    name: "menu.member",
    rtlName: "",
    icon: PersonIcon,
    component: Member,
    layout: "/admin",
    color: "#FFFFFF",
    weight: "500",
  },
  {
    id: "vps",
    path: "/vps",
    name: "menu.vps",
    rtlName: "",
    icon: VpsIcon,
    component: VPS,
    layout: "/admin",
    color: "#FFFFFF",
    weight: "500",
  },
  {
    id: "saleorder",
    path: "/saleorder",
    name: "menu.saleorder",
    rtlName: "",
    icon: MonetizationOnIcon,
    component: SaleOrder,
    layout: "/admin",
    color: "#FFFFFF",
    weight: "500",
  },
  {
    id: "signal",
    path: "/signal",
    name: "menu.signal",
    rtlName: "",
    icon: SignalIcon,
    component: Signal,
    layout: "/admin",
    color: "#FFFFFF",
    weight: "500",
  },
  {
    id: "product_menu",
    collapse: true,
    name: "menu.product",
    rtlName: "",
    icon: LocalAtmIcon,
    state: "productCollapse",
    color: "#FFFFFF",
    views: [
      {
        id: "product",
        path: "/product",
        name: "menu.product",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Product,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "coupon",
        path: "/coupon",
        name: "menu.coupon",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Coupon,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "category",
        path: "/category",
        name: "menu.category",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Category,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "symbol",
        path: "/symbol",
        name: "menu.symbol",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Symbol,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "expertadvisor",
        path: "/expertadvisor",
        name: "menu.expertadvisor",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: ExpertAdvisor,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "daytrade",
        path: "/daytrade",
        name: "menu.daytrade",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: DayTrade,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "tradertool",
        path: "/tradertool",
        name: "menu.tradertool",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: TraderTool,
        layout: "/admin",
        color: "#FFFFFF",
      },
    ],
  },
  {
    id: "report_menu",
    collapse: true,
    name: "menu.report",
    rtlName: "",
    icon: DescriptionIcon,
    state: "reportCollapse",
    color: "#FFFFFF",
    views: [
      {
        id: "rp_alllog",
        path: "/rp_alllog",
        name: "menu.rp_alllog",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpAllLog,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "rp_stat",
        path: "/rp_stat",
        name: "menu.rp_stat",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpStat,
        layout: "/admin",
        color: "#FFFFFF",
      },
    ],
  },
  {
    id: "admin_menu",
    collapse: true,
    name: "menu.admin",
    rtlName: "",
    icon: AssignmentIndIcon,
    state: "adminCollapse",
    color: "#FFFFFF",
    views: [
      {
        id: "user",
        path: "/user",
        name: "menu.user",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Employee,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "task",
        path: "/task",
        name: "menu.task",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Task,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "edit_profile",
        path: "/edit_profile",
        name: "menu.edit_profile",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: EditProfile,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "edit_pass",
        path: "/edit_pass",
        name: "menu.edit_pass",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: ChangePassword,
        layout: "/admin",
        color: "#FFFFFF",
      },
    ],
  },
  {
    id: "setting_menu",
    collapse: true,
    name: "menu.setting",
    rtlName: "",
    icon: SettingsIcon,
    state: "settingCollapse",
    color: "#FFFFFF",
    views: [
      {
        id: "wallet_setting",
        path: "/wallet_setting",
        name: "menu.wallet_setting",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: WalletSetting,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "slideshow",
        path: "/slideshow",
        name: "menu.slideshow",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: SlideShow,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "manual",
        path: "/manual",
        name: "menu.manual",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Manual,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "broker",
        path: "/broker",
        name: "menu.broker",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Broker,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "seminar",
        path: "/seminar",
        name: "menu.seminar",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Seminar,
        layout: "/admin",
        color: "#FFFFFF",
      },
    ],
  },
  {
    id: "tool_menu",
    collapse: true,
    name: "menu.tool",
    rtlName: "Admin Tool",
    icon: VerifiedUserIcon,
    state: "toolCollapse",
    color: "#FFFFFF",
    views: [
      {
        id: "bank",
        path: "/bank",
        name: "menu.bank",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Bank,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "permission",
        path: "/permission",
        name: "menu.permission",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Permission,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "menu",
        path: "/menu",
        name: "menu.menu",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Menu,
        layout: "/admin",
        color: "#FFFFFF",
      },
      {
        id: "log",
        path: "/log",
        name: "menu.log",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Log,
        layout: "/admin",
        color: "#FFFFFF",
      },
    ],
  },
];

export default dashRoutes;
