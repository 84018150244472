import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";

const useStyles = makeStyles(styles);

export default function TaskPermission({ data, checkValue, setValue }) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([...checkValue]);
  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // console.log(newChecked)
    setChecked(newChecked);
    setValue(newChecked);
  };
  const tableCellClasses = classnames(classes.tableCell);
  return (
    <GridContainer>
      {data &&
        data.map((item, i) => (
          <GridItem key={item.main.code} xs={12} sm={6} md={3}>
            <Card>
              <CardHeader>
                <h4 className={classes.cardTitle}>{item.main.name}</h4>
              </CardHeader>
              <CardBody>
                <Table className={classes.table}>
                  <TableBody>
                    {item.data &&
                      item.data.map((value) => (
                        <TableRow key={value.code} className={classes.tableRow}>
                          <TableCell
                            className={tableCellClasses}
                            style={{ width: "10px" }}
                          >
                            <Checkbox
                              checked={checked.indexOf(value.code) !== -1}
                              tabIndex={-1}
                              onClick={() => handleToggle(value.code)}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.root,
                              }}
                            />
                          </TableCell>
                          <TableCell className={tableCellClasses}>
                            {value.name}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </CardBody>
            </Card>
          </GridItem>
        ))}
    </GridContainer>
  );
}

TaskPermission.propTypes = {
  tasksIndexes: PropTypes.arrayOf(PropTypes.number),
  tasks: PropTypes.arrayOf(PropTypes.node),
  checkedIndexes: PropTypes.array,
};
