import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";
import i18n from "components/Translation/Translate.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Resizer from "react-image-file-resizer";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "components/UploadImage/UploadStyle";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CloseIcon from "@material-ui/icons/Close";
import UploadIcon from "@material-ui/icons/Publish";
import ImageIcon from "@material-ui/icons/Image";

const useStyles = makeStyles(styles);

export default function ProductDetail(props) {
  const classes = useStyles();
  const [file, setFile] = React.useState(null);
  const [fileData, setFileData] = React.useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(null);
  let fileInput = React.createRef();

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setFileData(reader.result);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageChangeResize = async (e) => {
    try {
      const file = e.target.files[0];
      const image = await resizeFile(file);
      setFile(file);
      setFileData(image);
      setImagePreviewUrl(image);
    } catch (err) {
      console.log(err);
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(null);
    fileInput.current.value = null;
  };

  const upload = () => {
    uploadData(fileData);
    setFile(null);
    setImagePreviewUrl(null);
    fileInput.current.value = null;
  };

  let { image, uploadData } = props;

  return (
    <div>
      <input
        ref={fileInput}
        accept="image/*"
        className={classes.input}
        style={{ display: "none" }}
        type="file"
        onChange={handleImageChange}
      />
      {file === null ? (
        <GridContainer>
          <GridItem xs={12} style={{ textAlign: "center" }}>
            <label>
              <Button
                round
                color="info"
                component="span"
                onClick={() => handleClick()}
              >
                <ImageIcon /> {i18n.t("upload.browse")}
              </Button>
            </label>
          </GridItem>
        </GridContainer>
      ) : (
        <div>
          <Dialog
            open={true}
            maxWidth={"md"}
            keepMounted
            fullWidth
            disableBackdropClick
            aria-labelledby="classic-modal-upload-title"
            aria-describedby="classic-modal-upload-description"
          >
            <DialogTitle
              id="classic-modal-upload-title"
              disableTypography
              className={classes.modalHeader}
            >
              <h4 className={classes.modalTitle}>{i18n.t("upload.upload")}</h4>
            </DialogTitle>
            <DialogContent
              className={classes.modalBody}
              style={{ minHeight: "500px", padding: "10px" }}
            >
              <div
                className="fileinput text-center"
                style={{ display: "block" }}
              >
                <div className={"thumbnail"} style={{ maxWidth: "100%" }}>
                  <img src={imagePreviewUrl ? imagePreviewUrl : image} />
                </div>
              </div>
            </DialogContent>
            <DialogActions style={{ overflow: "hidden" }}>
              <GridContainer style={{ margin: "10px" }}>
                <GridItem xs={6}>
                  <Button round color="danger" onClick={() => handleRemove()}>
                    <CloseIcon /> {i18n.t("upload.cancel")}
                  </Button>
                </GridItem>
                <GridItem xs={6} style={{ textAlign: "right" }}>
                  <Button round color="info" onClick={upload}>
                    <UploadIcon /> {i18n.t("upload.upload")}
                  </Button>
                </GridItem>
              </GridContainer>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
}

ProductDetail.propTypes = {
  image: PropTypes.string,
  uploadData: PropTypes.func,
};
