import { combineReducers } from 'redux';

import AppStateReducer from './AppStateReducer';
import UserReducer from './UserReducer';
import PermReducer from './PermReducer';

const rootReducer = combineReducers({
  appState: AppStateReducer,
  user: UserReducer,
  perm: PermReducer,
});

export default rootReducer;
