/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";

// @material-ui/icons
import SearchIcon from "@material-ui/icons/Search";
import DateRangeIcon from "@material-ui/icons/DateRange";

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initFind = {
  search: "",
  date_start: moment(Func.dateFirstMonth()),
  date_stop: moment(new Date()),
  order_by: "tb.date_create",
  order_sort: "desc",
  status: "ALL",
  product: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function RpAllLog({ t }) {
  const mod = "rp_alllog";
  const prefix = "report";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.id", name: t(`${prefix}.code`) },
    { code: "tb.logtype", name: `Mode` },
    { code: "tb.date_create", name: t(`${prefix}.date_create`), order: "desc" },
    { code: "tb.member_id", name: `Member ID` },
    { code: "tb.member_name", name: t(`${prefix}.member_name`) },
    { code: "tb.product_name", name: `Product` },
    { code: "tb.amount", name: t(`${prefix}.amount`) },
    { code: "tb.user_create", name: t(`${prefix}.enable`) },
  ];
  const alignValue = [0, 1, 2, 3, 6, 7];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);

  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [loading, setLoading] = useState(false);
  const [cboStatus, setCboStatus] = useState([]);
  const [cboProduct, setCboProduct] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, []);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);
        setCboProduct(result.product);
        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setLoading(true);
    setRecord([]);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateTimeFormat(data.date_start.toDate()),
      stop: Func.dateTimeFormat(data.date_stop.toDate()),
      status: data.status,
      product: data.product,
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              btnOpen(item),
              displayType(item),
              Func.dateTimeDisplay(item.date_create),
              item.member_id,
              <span>
                <img src={item.member_pic} width="20" /> {item.member_name}
              </span>,
              item.product_name,
              Func.numberDisplay(item.amount),
              displayStatus(item),
            ],
          ]);
        });

        if (result.data.length > 0)
          setRecord((record) => [
            ...record,
            [
              "",
              "",
              "",
              "",
              "",
              <b>{t(`${prefix}.total`)}</b>,
              <b>{Func.numberDisplay(result.sum.amount)}</b>,
              "",
            ],
          ]);

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const displayStatus = (item) => {
    switch (item.viewtype) {
      case "robot":
        return Func.checkDisplay(item.enable);
      default:
        return (
          <code className={`color-${item.status_color}`}>
            <i className={item.status_icon} /> {item.status_name}
          </code>
        );
    }
  };

  const displayType = (item) => {
    return (
      <code className={`color-${item.type_color}`}>
        <i className={item.type_icon} /> {item.type_name}
      </code>
    );
  };

  const btnOpen = (item) => {
    return (
      <NavLink to={`/admin/${item.viewtype}/${item.code}`}>
        <Button
          color="primary"
          simple
          className={classes.actionButton + " " + classes.actionButtonRound}
        >
          {item.id}
        </Button>
      </NavLink>
    );
  };

  const renderSearchDate = () => {
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days"),
      ],
      "3 Days": [moment(start).subtract(3, "days"), moment(end)],
      "5 Days": [moment(start).subtract(5, "days"), moment(end)],
      "1 Week": [moment(start).subtract(7, "days"), moment(end)],
      "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
      "1 Month": [moment(start).subtract(1, "months"), moment(end)],
    };
    return (
      <div>
        <DateTimeRangeContainer
          ranges={ranges}
          start={find.date_start}
          end={find.date_stop}
          local={{ format: "DD/MM/YYYY HH:mm", sundayFirst: false }}
          applyCallback={(start, stop) => {
            view({ ...find, date_start: start, date_stop: stop });
            setFind({ ...find, date_start: start, date_stop: stop });
          }}
        >
          <TextField
            fullWidth
            value={`${find.date_start.format("DD/MM/YYYY HH:mm")} 
            ${t(`element.to`)} 
            ${find.date_stop.format("DD/MM/YYYY HH:mm")}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {t("element.date")} :
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon />
                </InputAdornment>
              ),
              autoComplete: "off",
              readOnly: true,
            }}
          />
        </DateTimeRangeContainer>
      </div>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card className={classes.boxPrimary}>
            <CardHeader color="primary" icon className={classes.boxHead}>
              <h4 className={classes.boxTitle}>
                <i className="fas fa-file-alt color-primary" />
                {t("element.report")}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={6}>
                  <form
                    noValidate
                    onSubmit={(e) => {
                      e.preventDefault();
                      view(find);
                    }}
                  >
                    <TextField
                      id="search"
                      fullWidth
                      InputProps={{
                        onChange: (e) => {
                          setFind({ ...find, search: e.target.value, page: 1 });
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            {t("element.search")} :
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </form>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}></GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {renderSearchDate()}
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.status`)}
                      </MenuItem>
                      {cboStatus &&
                        cboStatus.map((item) => (
                          <MenuItem
                            key={item.code}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.code}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.product}
                      onChange={(e) => {
                        view({ ...find, product: e.target.value, page: 1 });
                        setFind({ ...find, product: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        Product
                      </MenuItem>
                      {cboProduct.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      renderHeader(header[4]),
                      renderHeader(header[5]),
                      renderHeader(header[6]),
                      renderHeader(header[7]),
                    ]}
                    tableData={record}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[7, 8, 9, 10, 11, 12, 13]}
                    colorsColls={[
                      "primary",
                      "primary",
                      "primary",
                      "success",
                      "danger",
                      "success",
                      "danger",
                    ]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}></InputLabel>
                    <Select
                      value={find.perPage}
                      onChange={(e) => {
                        view({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                        setFind({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      {cboPerPage &&
                        cboPerPage.map((item) => (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {t(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={8}
                  lg={9}
                  className={classes.right}
                >
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    count={pageTotal}
                    showFirstButton
                    showLastButton
                    page={pageNo}
                    onChange={(e, p) => {
                      view({ ...find, page: p });
                      setFind({ ...find, page: p });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderLoading()}
    </div>
  );
}

export default withNamespaces()(RpAllLog);
