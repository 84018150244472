/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TaskMenu from "./TaskMenu";
import TaskPermission from "./TaskPermission";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "../../services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  name_th: "",
  name_en: "",
  enable: "Y",
};

const cboPerPage = [
  { name: "element.show_10_record", id: 10 },
  { name: "element.show_20_record", id: 20 },
  { name: "element.show_100_record", id: 100 },
];

let page = 1;
let perPage = 10;

/* ========== ZONE Function ========== */
function Task({ t }) {
  const mod = "task";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const txtSearch = useRef(null);

  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState([]);
  const [perm, setPerm] = useState([]);
  const [attrMenu, setAttrMenu] = useState([]);
  const [attrPerm, setAttrPerm] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();

    if (id === undefined) {
      view();
    } else if (id === "add") {
      create();
    } else {
      setTab(0);
      open();
    }
  }, [id]);

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const selectChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.value });
  };

  const checkChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.checked ? "Y" : "N" });
  };

  const clickPage = (e, p) => {
    page = p;
    view();
  };

  const changePerPage = (e) => {
    page = 1;
    perPage = e.target.value;
    view();
  };

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setMenu(result.menu);
        setPerm(result.perm);
      } else {
        Func.exitApp();
      }
    });
  };

  const create = () => {
    setAttr(initAttr);
  };

  const view = () => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: txtSearch.current.value,
      page: page,
      per_page: perPage,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              item.code,
              item.name_th,
              item.name_en,
              Func.checkDisplay(item.enable),
              btn(item),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const open = () => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
        setAttrMenu(result.menu);
        setAttrPerm(result.perm);

        setLoading(false);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (!Func.verifyLength(attr.name_th, 1)) {
      result = false;
    } else if (!Func.verifyLength(attr.name_en, 1)) {
      result = false;
    }

    return result;
  };

  const add = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "add", {
              token: app.token,
              lang: app.lang,
              data: {
                name_th: attr.name_th,
                name_en: attr.name_en,
                enable: attr.enable,
              },
            }).then((result) => {
              setAlert(null);
              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");
                window.location.assign(`/admin/${mod}`);
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "edit", {
              token: app.token,
              lang: app.lang,
              code: attr.code,
              data: {
                name_th: attr.name_th,
                name_en: attr.name_en,
                enable: attr.enable,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                open();
                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const del = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_delete_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "delete", {
            token: app.token,
            lang: app.lang,
            code: item.code,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              view();
              showNotify(t("alert.deletecomplete"), "success");
            } else {
              showNotify(t("alert.deleteerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_delete_msg")} #{item.code}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const saveMenu = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "editperm", {
            token: app.token,
            lang: app.lang,
            code: attr.code,
            menu: attrMenu,
            perm: attrPerm,
          }).then((result) => {
            setAlert(null);
            setLoading(false);
            if (result.status === "OK") {
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const savePerm = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "editperm", {
            token: app.token,
            lang: app.lang,
            code: attr.code,
            menu: attrMenu,
            perm: attrPerm,
          }).then((result) => {
            setAlert(null);
            setLoading(false);

            if (result.status === "OK") {
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const btn = (item) => {
    return (
      <div>
        <NavLink to={`/admin/${mod}/${item.code}`}>
          <Button
            round
            color="success"
            className={classes.actionButton + " " + classes.actionButtonRound}
          >
            <EditIcon className={classes.icon} />
          </Button>
        </NavLink>
        <Button
          round
          color="danger"
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={() => del(item)}
        >
          <DeleteIcon className={classes.icon} />
        </Button>
      </div>
    );
  };

  const renderViewList = () => {
    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <AssignmentIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("element.viewlist")}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      inputRef={txtSearch}
                      style={{ margin: "10px 0 0 10px" }}
                      id="search"
                      InputProps={{
                        onChange: () => view(),
                        startAdornment: (
                          <InputAdornment position="start">
                            {t("element.search")} :
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                    <Button
                      color="white"
                      justIcon
                      round
                      className={classes.searchButton}
                    >
                      <SearchIcon
                        className={
                          classes.headerLinksSvg + " " + classes.searchIcon
                        }
                      />
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={6} className={classes.right}></GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12}>
                    <Table
                      striped
                      hover
                      tableHead={[
                        t(`attr.code`),
                        t(`${mod}.name_th`),
                        t(`${mod}.name_en`),
                        t("attr.enable"),
                        <NavLink to={`/admin/${mod}/add`}>
                          <Button round color="info" size="sm">
                            <AddIcon className={classes.icon} />
                          </Button>
                        </NavLink>,
                      ]}
                      tableData={record}
                      customHeadClassesForCells={[0, 3, 4]}
                      customHeadCellClasses={[
                        classes.center,
                        classes.center,
                        classes.right,
                      ]}
                      customClassesForCells={[0, 3, 4]}
                      customCellClasses={[
                        classes.center,
                        classes.center,
                        classes.right,
                      ]}
                      coloredColls={[]}
                      colorsColls={[]}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={3} lg={2}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel className={classes.selectLabel}></InputLabel>
                      <Select value={perPage} onChange={changePerPage}>
                        {cboPerPage &&
                          cboPerPage.map((item) => (
                            <MenuItem
                              key={item.id}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={item.id}
                            >
                              {t(item.name)}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    md={9}
                    lg={10}
                    className={classes.right}
                  >
                    <Pagination
                      style={{ float: "right", marginTop: "20px" }}
                      count={pageTotal}
                      showFirstButton
                      showLastButton
                      page={pageNo}
                      onChange={clickPage}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  };

  const renderAdd = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody className={classes.right}>
              <NavLink to={`/admin/${mod}`}>
                <Button color="warning">
                  <ArrowBackIcon /> {t("element.back")}
                </Button>
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.adddata")}</h4>
            </CardHeader>
            <CardBody>
              {renderInfo()}
              <GridContainer>
                <GridItem xs={12} className={classes.right}>
                  <Button color="success" onClick={add}>
                    <SaveIcon /> {t("element.save")}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>
                    {t(`${mod}.title`)} : {attr.name_th}
                  </h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <NavLink to={`/admin/${mod}`}>
                    <Button color="warning">
                      <ArrowBackIcon /> {t("element.back")}
                    </Button>
                  </NavLink>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.info"),
                tabIcon: DescriptionIcon,
                tabContent: (
                  <div>
                    {renderInfo()}
                    <GridContainer>
                      <GridItem xs={12} className={classes.right}>
                        <Button color="success" onClick={edit}>
                          <SaveIcon /> {t("element.save")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                ),
              },
              {
                tabName: t(`${mod}.menu`),
                tabIcon: DescriptionIcon,
                tabContent: renderTaskMenu(),
              },
              {
                tabName: t(`${mod}.perm`),
                tabIcon: DescriptionIcon,
                tabContent: renderTaskPermission(),
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12}>{renderDetail()}</GridItem>
      </GridContainer>
    );
  };

  const renderTaskMenu = () => {
    return (
      <div>
        <TaskMenu checkValue={attrMenu} data={menu} setValue={setAttrMenu} />
        <GridContainer>
          <GridItem xs={12} style={{ marginTop: "30px" }}>
            <GridContainer>
              <GridItem xs={12} style={{ textAlign: "right" }}>
                <Button color="success" onClick={saveMenu}>
                  <SaveIcon /> บันทึก
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  const renderTaskPermission = () => {
    return (
      <div>
        <TaskPermission
          checkValue={attrPerm}
          data={perm}
          setValue={setAttrPerm}
        />
        <GridContainer>
          <GridItem xs={12} style={{ marginTop: "30px" }}>
            <GridContainer>
              <GridItem xs={12} style={{ textAlign: "right" }}>
                <Button color="success" onClick={savePerm}>
                  <SaveIcon /> บันทึก
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText={
                <div>
                  {t(`${mod}.name_th`)}
                  <span className={classes.colorDanger}> *</span>
                </div>
              }
              id="name_th"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.name_th,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              labelText={
                <div>
                  {t(`${mod}.name_en`)}
                  <span className={classes.colorDanger}> *</span>
                </div>
              }
              id="name_en"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.name_en,
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attr.enable === "Y"}
                    name="enable"
                    tabIndex={-1}
                    onChange={checkChange}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={t("attr.enable")}
              />
            </div>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderPage = () => {
    if (id === undefined) {
      return renderViewList();
    } else if (id === "add") {
      return renderAdd();
    } else {
      return renderEdit();
    }
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_update}</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderPage()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(Task);
