/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AlertIcon from "@material-ui/icons/AddAlert";
import DescriptionIcon from "@material-ui/icons/Description";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";

import styles from "assets/jss/main-style.js";
import UploadImage from "./UploadLogo";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  name_th: "",
  name_en: "",
  lineid: "",
  linelink: "",
  minwithdraw: "",
  maxwithdraw_day: "",
  maxtransfer_time: "",
  mintransfer: "",
  mintransferback: "",
  minturnpro: "",
  onoff: "N",
  pro_onoff: "N",
  free_mintransfer: "",
  free_maxtransfer: "",
  free_maxout: "",
  free_minwithdraw: "",
  free_maxwithdraw: "",
  logo: "",
  logo_image: "",
};

/* ========== ZONE Function ========== */
function WalletSetting({ t }) {
  const mod = "wallet_setting";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);

  const [attr, setAttr] = useState(initAttr);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    open();
  }, []);

  const open = () => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        setLoading(false);
      }
    });
  };

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const edit = (myData) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "edit", {
            token: app.token,
            lang: app.lang,
            data: myData,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")}
      </SweetAlert>
    );
  };

  const editInfo = () => {
    edit({
      lineid: attr.lineid,
      linelink: attr.linelink,
    });
  };

  const editDeposit = () => {
    edit({
      spinprice: attr.spinprice,
    });
  };

  const editWithdraw = () => {
    edit({
      minwithdraw: attr.minwithdraw,
      maxwithdraw_day: attr.maxwithdraw_day,
    });
  };

  const editTransfer = () => {
    if (attr.minturnpro < 0) attr.minturnpro = 0;
    else if (attr.minturnpro > 50) attr.minturnpro = 50;

    edit({
      maxtransfer_time: attr.maxtransfer_time,
      mintransfer: attr.mintransfer,
      mintransferback: attr.mintransferback,
      minturnpro: attr.minturnpro,
      free_mintransfer: attr.free_mintransfer,
    });
  };

  const editSystem = () => {
    edit({
      name_th: attr.name_th,
      name_en: attr.name_en,
      onoff: attr.onoff,
      pro_onoff: attr.pro_onoff,
    });
  };

  const editFreeCredit = () => {
    edit({
      free_mintransfer: attr.free_mintransfer,
      free_maxtransfer: attr.free_maxtransfer,
      free_maxout: attr.free_maxout,
      free_minwithdraw: attr.free_minwithdraw,
      free_maxwithdraw: attr.free_maxwithdraw,
    });
  };

  const uploadImage = (filedata) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "editlogo", {
            token: app.token,
            lang: app.lang,
            filedata: filedata,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              open();
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("upload.upload")}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t(`${mod}.system`),
                tabIcon: DescriptionIcon,
                tabContent: renderSystem(),
              },
              {
                tabName: `Logo`,
                tabIcon: DescriptionIcon,
                tabContent: renderLogo(),
              },
              {
                tabName: t(`${mod}.info`),
                tabIcon: DescriptionIcon,
                tabContent: renderInfo(),
              },
              {
                tabName: t(`${mod}.deposit`),
                tabIcon: DescriptionIcon,
                tabContent: renderDeposit(),
              },
              {
                tabName: t(`${mod}.withdraw`),
                tabIcon: DescriptionIcon,
                tabContent: renderWithdraw(),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  labelText={t(`${mod}.lineid`)}
                  id="lineid"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.lineid,
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText={t(`${mod}.linelink`)}
                  id="linelink"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.linelink,
                  }}
                />
              </GridItem>
              <GridItem xs={12} className={classes.right}>
                <Button color="success" onClick={editInfo}>
                  <SaveIcon /> {t("element.save")}
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    );
  };

  const renderDeposit = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  labelText={t(`${mod}.spinprice`)}
                  id="spinprice"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "0.00",
                    onChange: inputChange,
                    value: attr.spinprice,
                    type: "number",
                  }}
                />
              </GridItem>
              <GridItem xs={12} className={classes.right}>
                <Button color="success" onClick={editDeposit}>
                  <SaveIcon /> {t("element.save")}
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    );
  };

  const renderWithdraw = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  labelText={t(`${mod}.minwithdraw`)}
                  id="minwithdraw"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "0.00",
                    onChange: inputChange,
                    value: attr.minwithdraw,
                    type: "number",
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText={t(`${mod}.maxwithdraw_day`)}
                  id="maxwithdraw_day"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "0.00",
                    onChange: inputChange,
                    value: attr.maxwithdraw_day,
                    type: "number",
                  }}
                />
              </GridItem>
              <GridItem xs={12} className={classes.right}>
                <Button color="success" onClick={editWithdraw}>
                  <SaveIcon /> {t("element.save")}
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    );
  };

  const renderSystem = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  labelText={t(`attr.name_th`)}
                  id="name_th"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "",
                    onChange: inputChange,
                    value: attr.name_th,
                    type: "text",
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText={t(`attr.name_en`)}
                  id="name_en"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    placeholder: "",
                    onChange: inputChange,
                    value: attr.name_en,
                    type: "text",
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={attr.onoff === "Y"}
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar,
                      }}
                      onChange={(e) =>
                        setAttr({
                          ...attr,
                          onoff: e.target.checked ? "Y" : "N",
                        })
                      }
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label={t(`${mod}.onoff`)}
                />
              </GridItem>
              <GridItem xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={attr.pro_onoff === "Y"}
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar,
                      }}
                      onChange={(e) =>
                        setAttr({
                          ...attr,
                          pro_onoff: e.target.checked ? "Y" : "N",
                        })
                      }
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label={t(`${mod}.pro_onoff`)}
                />
              </GridItem>
              <GridItem xs={12} className={classes.right}>
                <Button color="success" onClick={editSystem}>
                  <SaveIcon /> {t("element.save")}
                </Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    );
  };

  const renderLogo = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <div className="fileinput text-center">
            <div className={"thumbnail"} style={{ maxWidth: "100%" }}>
              <img src={attr.logo_image} />
            </div>
          </div>
          <UploadImage image={attr.logo_image} uploadData={uploadImage} />
          <p className={classes.center}>500 x 500 (px)</p>
        </GridItem>
      </GridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderEdit()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(WalletSetting);
