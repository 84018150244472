import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import AddAlert from "@material-ui/icons/AddAlert";
import LockOpenIcon from "@material-ui/icons/LockOpen";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import i18n from "components/Translation/Translate.js";

import { PostSecure } from "services/Request";
import { setToken } from "redux/actions/AppStateAction";
import { setUser } from "redux/actions/UserAction";
import { setPerm } from "redux/actions/PermAction";
import { useDispatch } from "react-redux";
import { usePageName } from "react-page-name";
import { withNamespaces } from "react-i18next";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

function Login({ t }) {
  const mod = "authen";
  const classes = useStyles();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.appState);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [googleAuthen, setGoogleAuthen] = useState("");
  const [alert, setAlert] = useState(null);
  const txtUser = useRef(null);

  usePageName(t("app.name") + " :: " + t(`${mod}.title`));

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    let id = setTimeout(function () {
      setCardAnimation("");
      txtUser.current.focus();
    }, 700);

    return function cleanup() {
      window.clearTimeout(id);
    };
  }, []);

  const signIn = (event) => {
    setAlert(null);
    event.preventDefault();

    dispatch(setToken(""));
    dispatch(setUser({}));
    dispatch(
      setPerm({
        menu: [],
        perm: {},
      })
    );

    PostSecure(mod, "signin", {
      lang: app.lang,
      username: username,
      password: password,
    }).then((result) => {
      if (result.status === "OK") {
        dispatch(setToken(result.data.token));
        dispatch(setUser(result.data));
        dispatch(
          setPerm({
            menu: result.menu,
            perm: result.perm,
          })
        );

        window.location.replace("/admin/dashboard");
      } else if (result.status === "AUTHEN") {
        dispatch(setUser(result.data));

        window.location.replace("/auth/authenticator");
      } else {
        showError(t(`${mod}.${result.msg}`));
      }
    });
  };

  const showError = (msg) => {
    setAlert(
      <CardFooter className={classes.justifyContentCenter}>
        <SnackbarContent message={msg} icon={AddAlert} color="danger" />
      </CardFooter>
    );
  };

  const renderLogin = () => {
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form noValidate onSubmit={signIn}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>
                  {t(`${mod}.administrator`)}
                </h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText={t(`${mod}.username`)}
                  id="username"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtUser,
                    onChange: (event) => setUsername(event.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText={t(`${mod}.password`)}
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (event) => setPassword(event.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type="submit" color="primary" round block>
                  <LockOpenIcon /> {t(`${mod}.signin`)}
                </Button>
              </CardFooter>
              {alert}
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    );
  };

  return <div className={classes.container}>{renderLogin()}</div>;
}

export default withNamespaces()(Login);
