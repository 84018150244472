import { SET_PERM, CLEAR_PERM } from '../types';

export const setPerm = (info) => (dispatch) => {
    dispatch({
        type: SET_PERM,
        payload: info,
    });
};

export const clearPerm = () => (dispatch) => {
    dispatch({
        type: CLEAR_PERM,
    });
};
