import axios from "axios";
import { Base64 } from "js-base64";

export function GetData(mod, id, myData, setUrl = "") {
  let url = `/app/req/${mod}-${id}`;

  if (setUrl) url = setUrl;

  return new Promise((resolve, reject) => {
    axios(url, {
      headers: { "Content-Type": "application/json" },
      method: "GET",
      data: myData,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function PostData(mod, id, myData, setUrl = "") {
  let url = `/app/req/${mod}-${id}`;

  if (setUrl) url = setUrl;

  return new Promise((resolve, reject) => {
    axios(url, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      data: myData,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function PostSecure(mod, id, myData, setUrl = "") {
  let url = `/app/req/${mod}-${id}`;

  if (setUrl) url = setUrl;
  let json = JSON.stringify(myData);
  let base64 = Base64.encode(json);
  let rand = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, 3);
  let str1 = base64.substr(0, 3);
  let str2 = base64.substr(3);

  return new Promise((resolve, reject) => {
    axios(url, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      data: `${str1}${rand}${str2}`,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function PutData(mod, id, myData, setUrl = "") {
  let url = `/app/req/${mod}-${id}`;

  if (setUrl) url = setUrl;

  return new Promise((resolve, reject) => {
    axios(url, {
      headers: { "Content-Type": "application/json" },
      method: "PUT",
      data: myData,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function DeleteData(mod, id, myData, setUrl = "") {
  let url = `/app/req/${mod}-${id}`;

  if (setUrl) url = setUrl;

  return new Promise((resolve, reject) => {
    axios(url, {
      headers: { "Content-Type": "application/json" },
      method: "DELETE",
      data: myData,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
