/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  credit_type: "D",
  credit: "",
  credit_amount: "",
  remark: "",
};

const initFind = {
  search: "",
  order_by: "tb.code",
  order_sort: "desc",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

const initSetup = {
  maxsetcredit: "0.00",
  maxsetcashback: "0.00",
  maxsetdiamond: "0",
};

/* ========== ZONE Function ========== */
function Credit({ t, id, open }) {
  const mod = "credit";
  const prefix = "member";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.code", name: t(`attr.code`), order: "desc" },
    { code: "tb.credit_type", name: t(`${prefix}.credit_type`) },
    { code: "tb.credit", name: t(`${prefix}.credit`) },
    { code: "tb.credit_before", name: t(`${prefix}.credit_before`) },
    { code: "tb.credit_after", name: t(`${prefix}.credit_after`) },
    { code: "tb.user_create", name: t(`${prefix}.user_create`) },
    { code: "tb.remark", name: t(`${prefix}.remark`) },
    { code: "tb.date_create", name: t(`${prefix}.date`) },
  ];
  const alignValue = [0, 1, 2, 3, 4, 5, 7];
  const alignItem = [
    classes.center,
    classes.center,
    classes.right,
    classes.right,
    classes.right,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const perm = useSelector((state) => state.perm.perm);
  const txtCredit = useRef(null);
  const [setup, setSetup] = useState(initSetup);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setSetup(result.setup);
        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      member: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              item.code,
              displayCredit(item.credit_type),
              Func.numberDisplay(item.credit_amount),
              Func.numberDisplay(item.credit_before),
              Func.numberDisplay(item.credit_balance),
              item.user_create,
              item.remark,
              Func.dateTimeDisplay(item.date_create),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const displayCredit = (inp) => {
    if (inp == "D") {
      return (
        <code className="color-success">
          <i className="far fa-plus-square" /> {t(`element.add`)}
        </code>
      );
    } else {
      return (
        <code className="color-danger">
          <i className="far fa-minus-square" /> {t(`element.sub`)}
        </code>
      );
    }
  };

  const add = () => {
    if (
      attr.credit_amount < 1 ||
      attr.credit_amount * 1 > setup.maxsetcredit * 1
    ) {
      setAttr({ ...attr, credit: "", credit_amount: "" });
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "add", {
              token: app.token,
              lang: app.lang,
              data: {
                kind: "MEMBER",
                member_code: id,
                credit_type: attr.credit_type,
                credit_amount: attr.credit_amount,
                credit:
                  attr.credit_type == "W"
                    ? attr.credit_amount * -1
                    : attr.credit_amount,
                remark: attr.remark,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                open(id);
                view(find);
                setAttr(initAttr);
                showNotify(t("alert.addcomplete"), "success");
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <div>
        <GridContainer>
          {perm.editwallet != "Y" ? null : (
            <GridItem xs={12}>{renderInfo()}</GridItem>
          )}
          <GridItem xs={12}>
            <Table
              striped
              hover
              tableHead={[
                renderHeader(header[0]),
                renderHeader(header[1]),
                renderHeader(header[2]),
                renderHeader(header[3]),
                renderHeader(header[4]),
                renderHeader(header[5]),
                renderHeader(header[6]),
                renderHeader(header[7]),
              ]}
              tableData={record}
              customHeadClassesForCells={alignValue}
              customHeadCellClasses={alignItem}
              customClassesForCells={alignValue}
              customCellClasses={alignItem}
              coloredColls={[2, 3, 4]}
              colorsColls={["primary", "danger", "success"]}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel className={classes.selectLabel}></InputLabel>
              <Select
                value={find.perPage}
                onChange={(e) => {
                  view({
                    ...find,
                    page: 1,
                    perPage: e.target.value,
                    page: 1,
                  });
                  setFind({
                    ...find,
                    page: 1,
                    perPage: e.target.value,
                    page: 1,
                  });
                }}
              >
                {cboPerPage &&
                  cboPerPage.map((item) => (
                    <MenuItem
                      key={item.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.id}
                    >
                      {t(item.name)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={8} lg={9} className={classes.right}>
            <Pagination
              style={{ float: "right", marginTop: "20px" }}
              count={pageTotal}
              showFirstButton
              showLastButton
              page={pageNo}
              onChange={(e, p) => {
                view({ ...find, page: p });
                setFind({ ...find, page: p });
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="credit_type"
                  className={classes.labelSelect}
                >
                  {t(`${prefix}.credit_type`)}
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={attr.credit_type}
                  inputProps={{
                    name: "credit_type",
                  }}
                  onChange={(e) =>
                    setAttr({ ...attr, credit_type: e.target.value })
                  }
                >
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="D"
                  >
                    {t(`element.add`)}
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value="W"
                  >
                    {t(`element.sub`)}
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <CustomInput
                labelText={t(`${prefix}.credit`)}
                id="credit_amount"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  inputRef: txtCredit,
                  placeholder: `1 - ${setup.maxsetcredit}`,
                  type: "number",
                  onChange: inputChange,
                  value: attr.credit_amount,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText={t(`${prefix}.remark`)}
                id="remark"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: inputChange,
                  value: attr.remark,
                }}
              />
            </GridItem>
            <GridItem xs={12} className={classes.center}>
              <Button onClick={add} color="success">
                <SaveIcon /> {t(`element.save`)}
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(Credit);
