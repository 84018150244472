import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Lock from "@material-ui/icons/Lock";
import Notifications from "@material-ui/icons/Notifications";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import LanguageIcon from "@material-ui/icons/Language";

// core components
import Button from "components/CustomButtons/Button.js";
import i18n from "components/Translation/Translate.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import { setLanguage } from "redux/actions/AppStateAction";
import { PostData } from "services/Request";
import { setUser } from "redux/actions/UserAction";
import { setPerm } from "redux/actions/PermAction";
import { setToken } from "redux/actions/AppStateAction";
import * as Func from "services/Function";

import enFlag from "assets/img/flags/GB.png";
import thFlag from "assets/img/flags/TH.png";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.appState);
  // const [countDeposit, setCountDeposit] = useState("0");
  // const [notifyDeposit, setNotifyDeposit] = useState([]);
  const [openDeposit, setOpenDeposit] = useState(null);
  // const [countWithdraw, setCountWithdraw] = useState("0");
  // const [notifyWithdraw, setNotifyWithdraw] = useState([]);
  const [openWithdraw, setOpenWithdraw] = useState(null);
  const [openProfile, setOpenProfile] = useState(null);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { notifyDeposit, notifyWithdraw } = props;

  const clickDeposit = (event) => {
    if (openDeposit && openDeposit.contains(event.target)) {
      setOpenDeposit(null);
    } else {
      setOpenDeposit(event.currentTarget);
    }
  };

  const closeDeposit = () => {
    setOpenDeposit(null);
  };

  const clickWithdraw = (event) => {
    if (openWithdraw && openWithdraw.contains(event.target)) {
      setOpenWithdraw(null);
    } else {
      setOpenWithdraw(event.currentTarget);
    }
  };

  const closeWithdraw = () => {
    setOpenWithdraw(null);
  };

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: false,
  });

  const wrapper = classNames({
    [classes.wrapperRTL]: false,
  });

  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  useEffect(() => {
    if (app.token.length < 10) Func.exitApp();

    // if (checkNotify)
    // init();
  }, []);

  // const init = () => {
  //   PostData("notification", "init", {
  //     token: app.token,
  //     lang: app.lang,
  //   }).then((result) => {
  //     if (result.status === "OK") {
  //       setNotifyDeposit(result.deposit);
  //       setCountDeposit(result.count_deposit);

  //       setNotifyWithdraw(result.withdraw);
  //       setCountWithdraw(result.count_withdraw);
  //     }
  //   });
  // };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };

  const logout = () => {
    setLoading(true);
    dispatch(setUser({}));
    dispatch(setToken(""));
    dispatch(
      setPerm({
        menu: [],
        perm: {},
      })
    );

    PostData("authen", "logout", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      window.location.replace("/auth/login");
    });
  };

  const renderNotifyDeposit = () => {
    return (
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openDeposit ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={clickDeposit}
          className={classes.buttonLink}
          muiClasses={{
            label: "",
          }}
        >
          <AddAlertIcon
            className={classes.headerLinksSvg + " " + classes.links}
          />
          <span
            className={classes.notifications}
            style={{ background: "green", padding: "0 2px" }}
          >
            {notifyDeposit.count > 20 ? "20+" : notifyDeposit.count}
          </span>
          <Hidden mdUp implementation="css">
            <span onClick={clickDeposit} className={classes.linkText}>
              {i18n.t(`notification.deposit`)}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openDeposit)}
          anchorEl={openDeposit}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openDeposit,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="deposit-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={closeDeposit}>
                  <MenuList role="menu">
                    {notifyDeposit.data.map((item) => (
                      <MenuItem
                        key={item.code}
                        onClick={() => {
                          window.location.replace(
                            `/admin/deposit/${item.code}`
                          );
                        }}
                        className={dropdownItem}
                      >
                        <img src={item.filepic} width="20" /> {item.member_name}{" "}
                        (
                        <span style={{ color: "green" }}>
                          {Func.numberDisplay(item.amount)}
                        </span>{" "}
                        ฿)
                      </MenuItem>
                    ))}
                    <MenuItem
                      onClick={() => {
                        window.location.replace(`/admin/deposit`);
                      }}
                      className={dropdownItem}
                    >
                      <i className="fas fa-list-ul"></i>{" "}
                      {i18n.t(`notification.showall`)}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  const renderNotifyWithdraw = () => {
    return (
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openWithdraw ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={clickWithdraw}
          className={classes.buttonLink}
          muiClasses={{
            label: "",
          }}
        >
          <NotificationsActiveIcon
            className={classes.headerLinksSvg + " " + classes.links}
          />
          <span
            className={classes.notifications}
            style={{ background: "red", padding: "0 2px" }}
          >
            {notifyWithdraw.count > 20 ? "20+" : notifyWithdraw.count}
          </span>
          <Hidden mdUp implementation="css">
            <span onClick={clickWithdraw} className={classes.linkText}>
              {i18n.t(`notification.withdraw`)}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openWithdraw)}
          anchorEl={openWithdraw}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openWithdraw,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="withdraw-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={closeWithdraw}>
                  <MenuList role="menu">
                    {notifyWithdraw.data.map((item) => (
                      <MenuItem
                        key={item.code}
                        onClick={() => {
                          window.location.replace(
                            `/admin/withdraw/${item.code}`
                          );
                        }}
                        className={dropdownItem}
                      >
                        <img src={item.filepic} width="20" /> {item.member_name}{" "}
                        (
                        <span style={{ color: "red" }}>
                          {Func.numberDisplay(item.amount)}
                        </span>{" "}
                        ฿)
                      </MenuItem>
                    ))}
                    <MenuItem
                      onClick={() => {
                        window.location.replace(`/admin/withdraw`);
                      }}
                      className={dropdownItem}
                    >
                      <i className="fas fa-list-ul"></i>{" "}
                      {i18n.t(`notification.showall`)}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  const renderTranslation = () => {
    return (
      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Language"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
          muiClasses={{
            label: "",
          }}
        >
          <LanguageIcon
            className={classes.headerLinksSvg + " " + classes.links}
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {i18n.t(`element.changelanguage`)}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={() => changeLanguage("th")}
                      className={dropdownItem}
                    >
                      <img src={thFlag} alt="..." /> ภาษาไทย
                    </MenuItem>
                    <MenuItem
                      onClick={() => changeLanguage("en")}
                      className={dropdownItem}
                    >
                      <img src={enFlag} alt="..." /> English
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  const renderLogout = () => {
    return (
      <Button
        color="transparent"
        simple
        aria-label="Lock out"
        justIcon
        className={classes.buttonLink}
        muiClasses={{
          label: "",
        }}
        onClick={logout}
      >
        <Lock className={classes.headerLinksSvg + " " + classes.links} />
        <Hidden mdUp implementation="css">
          <span className={classes.linkText}>{i18n.t(`element.lockout`)}</span>
        </Hidden>
      </Button>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <div className={wrapper}>
      {notifyDeposit.count > 0 ? renderNotifyDeposit() : null}
      {notifyWithdraw.count > 0 ? renderNotifyWithdraw() : null}

      {/* {renderNotifyDeposit()()}
      {renderNotifyWithdraw()()} */}
      {renderTranslation()}
      {renderLogout()}
      {renderLoading()}
    </div>
  );
}

HeaderLinks.propTypes = {
  checkNotify: PropTypes.bool,
};
