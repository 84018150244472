/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";
import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadImage from "./UploadImage";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckIcon from "@material-ui/icons/Check";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ImageIcon from "@material-ui/icons/Image";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
  filepic: "",
  image: "",
  service_life: "",
  price: "",
  product_code: "",
  count: "",
  date_expire: new Date(),
  enable: "Y",
  firstexpire: "Y",
};

const initFind = {
  search: "",
  order_by: "tb.code",
  order_sort: "desc",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function Coupon({ t }) {
  const mod = "coupon";
  const prefix = "attr";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.filepic", name: t(`${prefix}.image`), sort: false },
    { code: "tb.code", name: t(`${prefix}.code`), order: "asc" },
    { code: "tb.id", name: t(`${prefix}.id`) },
    { code: "tb.member_code", name: "Member" },
    { code: "tb.date_expire", name: "วันหมดอายุ" },
    { code: "tb.price", name: `ส่วนลด` },
    { code: "tb.enable", name: t(`${prefix}.enable`) },
    { code: "tb.firstexpire", name: `ใช้ครั้งเดียว` },
  ];
  const alignValue = [0, 1, 2, 4, 5, 6, 7, 8];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cboProduct, setCboProduct] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboProduct(result.product);

        if (id === undefined) {
          view(find);
        } else if (id === "add") {
          setAttr(initAttr);
        } else {
          setTab(0);
          open();
        }
      } else {
        Func.exitApp();
      }
    });
  };

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const selectChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.value });
  };

  const checkChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.checked ? "Y" : "N" });
  };

  const view = (data) => {
    setRecord([]);
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      show: "ALL",
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              <img src={item.image} width="30" />,
              item.code,
              item.id,
              item.member_name,
              Func.dateDisplay(item.date_expire),
              item.price,
              Func.checkDisplay(item.enable),
              Func.checkDisplay(item.firstexpire),
              btn(item),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const open = () => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr({
          ...result.data,
          date_expire: new Date(result.data.date_expire),
        });

        setLoading(false);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (!Func.verifyLength(attr.price, 1)) result = false;

    return result;
  };

  const add = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "add", {
              token: app.token,
              lang: app.lang,
              data: {
                date_expire: Func.dateFormat(attr.date_expire),
                price: attr.price,
                product_code: attr.product_code,
                enable: attr.enable,
                firstexpire: attr.firstexpire,
              },
              count: attr.count,
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");
                window.location.assign(`/admin/${mod}`);
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "edit", {
              token: app.token,
              lang: app.lang,
              code: attr.code,
              data: {
                date_expire: Func.dateFormat(attr.date_expire),
                price: attr.price,
                product_code: attr.product_code,
                enable: attr.enable,
                firstexpire: attr.firstexpire,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                open();
                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const del = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_delete_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "delete", {
            token: app.token,
            lang: app.lang,
            code: item.code,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              view(find);
              showNotify(t("alert.deletecomplete"), "success");
            } else {
              showNotify(t("alert.deleteerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_delete_msg")} #{item.code}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <>
        <NavLink to={`/admin/${mod}/${item.code}`}>
          <Button
            round
            color="success"
            className={classes.actionButton + " " + classes.actionButtonRound}
          >
            <EditIcon className={classes.icon} />
          </Button>
        </NavLink>
        <Button
          round
          color="danger"
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={() => del(item)}
        >
          <DeleteIcon className={classes.icon} />
        </Button>
      </>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const uploadImage = (filedata) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "upload", {
            code: attr.code,
            token: app.token,
            lang: app.lang,
            filedata: filedata,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              open();
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("upload.upload")}
      </SweetAlert>
    );
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.viewlist")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="search"
                    fullWidth
                    InputProps={{
                      onChange: (e) => {
                        view({ ...find, search: e.target.value, page: 1 });
                        setFind({ ...find, search: e.target.value, page: 1 });
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {t("element.search")} :
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      renderHeader(header[4]),
                      renderHeader(header[5]),
                      renderHeader(header[6]),
                      renderHeader(header[7]),
                      <NavLink to={`/admin/${mod}/add`}>
                        <Button round color="info" size="sm">
                          <AddIcon className={classes.icon} />{" "}
                          {t("element.add")}
                        </Button>
                      </NavLink>,
                    ]}
                    tableData={record}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[]}
                    colorsColls={[]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}></InputLabel>
                    <Select
                      value={find.perPage}
                      onChange={(e) => {
                        view({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                        setFind({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      {cboPerPage &&
                        cboPerPage.map((item) => (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {t(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={8}
                  lg={9}
                  className={classes.right}
                >
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    count={pageTotal}
                    showFirstButton
                    showLastButton
                    page={pageNo}
                    onChange={(e, p) => {
                      view({ ...find, page: p });
                      setFind({ ...find, page: p });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderAdd = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody className={classes.right}>
              <NavLink to={`/admin/${mod}`}>
                <Button color="warning">
                  <ArrowBackIcon /> {t("element.back")}
                </Button>
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.adddata")}</h4>
            </CardHeader>
            <CardBody>
              {renderInfo()}

              <GridContainer>
                <GridItem xs={12} className={classes.right}>
                  <Button color="success" onClick={add}>
                    <SaveIcon /> {t("element.save")}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>ID : {attr.id}</h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <NavLink to={`/admin/${mod}`}>
                    <Button color="warning">
                      <ArrowBackIcon /> {t("element.back")}
                    </Button>
                  </NavLink>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.info"),
                tabIcon: DescriptionIcon,
                tabContent: (
                  <div>
                    {renderInfo()}
                    {/* <GridContainer>
                      <GridItem xs={12} className={classes.right}>
                        <Button color="success" onClick={edit}>
                          <SaveIcon /> {t("element.save")}
                        </Button>
                      </GridItem>
                    </GridContainer> */}
                  </div>
                ),
              },
              {
                tabName: t(`${prefix}.image`),
                tabIcon: ImageIcon,
                tabContent: <div>{renderImage()}</div>,
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12}>{renderDetail()}</GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          {/* <GridItem xs={12} sm={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="product_code"
                className={classes.labelSelect}
              >
                Signal
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={attr.product_code}
                inputProps={{
                  name: "product_code",
                }}
                onChange={selectChange}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {t("element.pleaseselect")}
                </MenuItem>
                {cboProduct.map((item) => (
                  <MenuItem
                    key={item.code}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={item.code}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem> */}
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <InputLabel className={classes.labelDpk}>วันหมดอายุ</InputLabel>
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                inputProps={{
                  placeholder: "__/__/____",
                }}
                dateFormat="DD/MM/yyyy"
                closeOnSelect
                value={attr.date_expire}
                onChange={(value) => {
                  setAttr({ ...attr, date_expire: value });
                }}
                className={classes.center}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              labelText="ส่วนลด"
              id="price"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.price,
                type: "number",
              }}
              alignCenter
            />
          </GridItem>
          {id === "add" ? (
            <GridItem xs={12} sm={6} md={4} lg={3}>
              <CustomInput
                labelText="จำนวนการสร้าง"
                id="count"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: inputChange,
                  value: attr.count,
                  type: "number",
                }}
                alignCenter
              />
            </GridItem>
          ) : null}
          <GridItem xs={12}>
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attr.enable === "Y"}
                    name="enable"
                    tabIndex={-1}
                    onChange={checkChange}
                    checkedIcon={<CheckIcon className={classes.checkedIcon} />}
                    icon={<CheckIcon className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={t(`${prefix}.enable`)}
              />
            </div>
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attr.firstexpire === "Y"}
                    name="firstexpire"
                    tabIndex={-1}
                    onChange={checkChange}
                    checkedIcon={<CheckIcon className={classes.checkedIcon} />}
                    icon={<CheckIcon className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="ใช้งานได้ครั้งเดียว"
              />
            </div>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_update}</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderImage = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardBody>
              <div className="fileinput text-center">
                <div
                  className={"thumbnail"}
                  style={{ maxWidth: "100%", textAlign: "center" }}
                >
                  <img src={attr.image} alt="..." />
                </div>
              </div>
              <UploadImage uploadData={uploadImage} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderPage = () => {
    if (id === undefined) {
      return renderViewList();
    } else if (id === "add") {
      return renderAdd();
    } else {
      return renderEdit();
    }
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderPage()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(Coupon);
